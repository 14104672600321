import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export const useIsMobile = () => {
  const { width, height } = useWindowDimensions();

  const maxWidth = 750;
  const appWidth = Math.min(width, maxWidth);

  return useMemo(
    () => ({
      width: appWidth,
      height,
      isMobile: appWidth <= 576,
    }),
    [appWidth, height],
  );
};
