import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { ProductBrand, ProductCategory, ProductRange } from '../screens/Products/models';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductRanges: builder.mutation<ProductBrand[], any>({
      query: () => ({
        url: Endpoints.getProductRanges,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductRangesFromParent: builder.mutation<ProductRange[], any>({
      query: ({ tid }) => ({
        url: `${Endpoints.getProductRanges}/${tid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductsFromRange: builder.mutation<ProductCategory, any>({
      query: ({ categorySlug }) => ({
        url: `${Endpoints.getProducts}/${categorySlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),

    getRelatedProducts: builder.mutation<ProductRange[], any>({
      query: () => ({
        url: Endpoints.getRelatedProducts,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductDetails: builder.mutation<any, any>({
      query: (productSlug) => ({
        url: `${Endpoints.getProductDetails}/${productSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductRangesMutation,
  useGetProductRangesFromParentMutation,
  useGetProductsFromRangeMutation,
  useGetRelatedProductsMutation,
  useGetProductDetailsMutation,
} = productsApi;
