import {
  LoginValidateEmailRequest,
  MigrationLoginRequest,
  MigrationForm,
  UserAddress,
} from './../models/User.model';
import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { LoginRequest, UserDataModel, UserResponse } from '../models/User.model';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchStaticFileId: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `${Endpoints.staticFileId}/${id}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    fetchStaticFilePages: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.staticFilePages,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
      transformResponse: (response: any) => response.results,
    }),
    fetchAccountsLimitReached: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.accountsLimitReached,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    identifyUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${Endpoints.userIdentify}`,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    validateReferralLink: builder.mutation<any, any>({
      query: (referral) => ({
        url: `${Endpoints.validateReferralLink}`,
        method: 'GET',
        params: { referral: referral },
        headers: {
          'Content-type': 'application/JSON',
        },
      }),
    }),
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (body) => ({
        url: Endpoints.login,
        method: 'POST',
        body: `username=${encodeURIComponent(body.username)}&password=${encodeURIComponent(
          body.password,
        )}&grant_type=${body.grant_type}`,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
    migrationLogin: builder.mutation<MigrationForm, MigrationLoginRequest>({
      query: (body) => ({
        url: Endpoints.migrationLogin,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    migrateUser: builder.mutation<any, MigrationForm>({
      query: (body) => ({
        url: Endpoints.migrateUser,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    loginValidateEmail: builder.mutation<any, LoginValidateEmailRequest>({
      query: ({ consumerID, new_email, old_email }) => ({
        url: Endpoints.loginValidateEmail,
        method: 'PATCH',
        body: {
          new_email,
          old_email,
        },
        headers: {
          'Content-type': 'application/json',
          consumerID,
        },
      }),
    }),
    register: builder.mutation<any, any>({
      query: (registerForm) => ({
        url: Endpoints.register,
        method: 'POST',
        body: registerForm,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    customersValidate: builder.mutation<any, any>({
      query: (userData) => ({
        url: Endpoints.customersValidate,
        method: 'POST',
        body: userData,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: ({ email }) => ({
        url: `${Endpoints.forgotPassword}`,
        method: 'GET',
        params: {
          email,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProfileCompletionPoints: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.profileCompletionPoints,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getSurveyCompletionPoints: builder.mutation<any, void>({
      query: () => ({
        url: Endpoints.surveyCompletionPoints,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    forgotPasswordByPhone: builder.mutation<any, any>({
      query: ({ phone }) => ({
        url: `${Endpoints.forgotPasswordByPhone}`,
        method: 'GET',
        params: {
          phone,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: ({ password, token, timestamp, cid, validate }) => ({
        url: Endpoints.forgotPassword,
        method: 'PATCH',
        params: {
          token,
          timestamp,
          cid,
          validate,
        },
        body: password ? { password } : {},
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    resetPasswordByPhone: builder.mutation<any, any>({
      query: (body) => ({
        url: Endpoints.forgotPasswordByPhone,
        method: 'PATCH',
        body: body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    fetchUserData: builder.mutation<UserDataModel, any>({
      query: ({ consumerId }) => ({
        url: `${Endpoints.register}/${consumerId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    emailValidation: builder.mutation<any, any>({
      query: (consumerId) => ({
        url: `${Endpoints.validateEmail}`,
        params: {
          cid: consumerId,
        },
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    onSuccessEmailValidation: builder.mutation<any, any>({
      query: ({ token, timestamp, cid }) => ({
        url: `${Endpoints.validateEmail}/`,
        params: {
          token,
          timestamp,
          cid,
        },
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    onVerifySMSValidation: builder.mutation<any, any>({
      query: ({ externalUserId, smsCode }) => ({
        url: `${Endpoints.validateSMS}/${externalUserId}/${smsCode}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    SMSValidation: builder.mutation<any, any>({
      query: (consumerId) => ({
        url: `${Endpoints.validateSMS}`,
        body: {
          consumerId: consumerId,
        },
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    sendSMSValidation: builder.mutation<any, any>({
      query: ({ phone, migration = false }) => ({
        url: Endpoints.sendSMSCode,
        body: {
          phone: { value: phone.value },
          migration,
        },
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    updateUserData: builder.mutation<UserDataModel, any>({
      query: (body) => ({
        url: Endpoints.register,
        method: 'PATCH',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    deleteUserProfile: builder.mutation<UserDataModel, any>({
      query: (body) => ({
        url: Endpoints.register,
        method: 'PATCH',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProfileAddress: builder.mutation<UserAddress, any>({
      query: () => ({
        url: Endpoints.getProfileAddress,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    patchOnBoardingStatus: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.patchOnBoardingStatus,
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchStaticFileIdMutation,
  useFetchStaticFilePagesMutation,
  useFetchAccountsLimitReachedMutation,
  useLoginMutation,
  useLoginValidateEmailMutation,
  useMigrationLoginMutation,
  useMigrateUserMutation,
  useRegisterMutation,
  useFetchUserDataMutation,
  useEmailValidationMutation,
  useOnSuccessEmailValidationMutation,
  useUpdateUserDataMutation,
  useForgotPasswordMutation,
  useForgotPasswordByPhoneMutation,
  useSMSValidationMutation,
  useResetPasswordMutation,
  useResetPasswordByPhoneMutation,
  useDeleteUserProfileMutation,
  useOnVerifySMSValidationMutation,
  useSendSMSValidationMutation,
  useIdentifyUserMutation,
  useCustomersValidateMutation,
  useGetProfileAddressMutation,
  useValidateReferralLinkMutation,
  usePatchOnBoardingStatusMutation,
  useGetSurveyCompletionPointsMutation,
  useGetProfileCompletionPointsMutation,
} = authApi;
