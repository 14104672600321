import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import {
  CreateIDScanSessionResponse,
  GetIDScanSessionRequest,
  ValidateFaceCaptureRequest,
  YotiValidationResponse,
  YotiUserUpdateRequest,
  GetIDScanSessionResponse,
} from '../models/Yoti.model';

export const yotiApi = api.injectEndpoints({
  endpoints: (builder) => ({
    validateFaceCapture: builder.mutation<YotiValidationResponse, ValidateFaceCaptureRequest>({
      query: (body) => ({
        url: `${Endpoints.yotiValidation}`,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    createIDScanSession: builder.mutation<CreateIDScanSessionResponse, {}>({
      query: () => ({
        url: `${Endpoints.yotiValidationIDScanSession}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getIDScanSession: builder.mutation<GetIDScanSessionResponse, GetIDScanSessionRequest>({
      query: ({ sessionId }) => ({
        url: `${Endpoints.yotiValidationIDScanSession}`,
        method: 'GET',
        params: {
          sessionId,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    deleteIDScanSession: builder.mutation<any, GetIDScanSessionRequest>({
      query: (body) => ({
        url: `${Endpoints.yotiValidationIDScanSession}`,
        method: 'DELETE',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    yotiUserUpdate: builder.mutation<any, YotiUserUpdateRequest>({
      query: (body) => ({
        url: `${Endpoints.yotiUserUpdate}`,
        method: 'PATCH',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useValidateFaceCaptureMutation,
  useCreateIDScanSessionMutation,
  useGetIDScanSessionMutation,
  useDeleteIDScanSessionMutation,
  useYotiUserUpdateMutation,
} = yotiApi;
