import { useEffect, useState } from 'react';

import { NotificationData } from '../../../models/Feed.model';
import {
  useGetNotificationListMutation,
  useUpdateNotificationMutation,
} from '../../../services/feed';
import BATAnnouncementToast from './BATAnnouncementToast';
import BATAnnouncementSticky from './BATAnnouncementSticky';
import BATAnnouncementPopup from './BATAnnouncementPopup';

const BATAnnouncements = () => {
  const [toasts, setToasts] = useState<NotificationData[]>([]);
  const [sticky, setSticky] = useState<NotificationData[]>([]);
  const [popups, setPopups] = useState<NotificationData[]>([]);

  const [updateNotification] = useUpdateNotificationMutation();
  const [getNotificationList] = useGetNotificationListMutation();

  const unreadNotifications = (notifications: NotificationData[]) => {
    const unreadNotifications = notifications.filter((notification) => !notification.status);
    return unreadNotifications;
  };

  useEffect(() => {
    getNotificationList({})
      .unwrap()
      .then((data) => {
        if (!Array.isArray(data.toasts)) {
          const unreadToasts = unreadNotifications(data.toasts.data);
          setToasts(unreadToasts);
        }
        if (!Array.isArray(data.sticky)) {
          const unreadSticky = unreadNotifications(data.sticky.data);
          setSticky(unreadSticky);
        }
        if (!Array.isArray(data['pop-ups'])) {
          const unreadPopups = unreadNotifications(data['pop-ups'].data);
          setPopups(unreadPopups);
        }
      });
  }, []);

  const handleSeenNotification = (id: string) => {
    updateNotification({ status: 'view', nid: id }).unwrap();
  };

  return (
    <>
      <BATAnnouncementToast
        handleSeenNotification={handleSeenNotification}
        notifications={toasts}
      />
      <BATAnnouncementSticky
        handleSeenNotification={handleSeenNotification}
        notifications={sticky}
      />
      <BATAnnouncementPopup
        handleSeenNotification={handleSeenNotification}
        notifications={popups}
      />
    </>
  );
};
export default BATAnnouncements;
