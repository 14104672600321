import { DataLayerEventName, EventCategory, EventData } from '../models/GA.model';
import { WindowObj } from '../models/GA.model';
import Cookies from 'js-cookie';

export const pushGAEvent = (
  eventName: DataLayerEventName,
  eventCategory?: EventCategory,
  eventDetails?: EventData,
): void => {
  const toObject = (params: URLSearchParams): Record<string, string | null> => {
    const rv: Record<string, string | null> = {};
    params.forEach((value, key) => {
      rv[key] = value;
    });
    return rv;
  };

  const consent = toObject(new URLSearchParams(Cookies.get('OptanonConsent')));
  const groups = consent.groups?.split(',') ?? [];
  if (groups.includes('C0002:1')) {
    const windowObj = window as WindowObj;
    const dataLayer = windowObj.dataLayer || [];

    const dataLayerItem: any = {
      event: eventName,
    };
    if (eventCategory && eventDetails) {
      dataLayerItem[eventCategory] = eventDetails;
    }
    if (eventName === 'datalayer_initialized') {
      // Because of the way onStateChange works in NavigationContainer (@react-navigation),
      // used in App.tsx, I came to the conclusion that it is necessary to delay the update
      // of the dataLayer a bit, to allow time for it to update window.location.href.
      // I know that maybe it's not the best solution, but I haven't found anything better... ✌️
      setTimeout(() => {
        const virtualPageLocation = localStorage.getItem('virtualPageLocation');
        localStorage.removeItem('virtualPageLocation');
        dataLayerItem.virtualPageLocation = virtualPageLocation || window.location.href;
        dataLayer.push(dataLayerItem);
      }, 250);
    } else {
      dataLayer.push(dataLayerItem);
    }
  }
};
