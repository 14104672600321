import constants from './constants';

const globalStyleBorders = {
  borderRadius: {
    borderRadius: constants.borderRadius,
  },
  borderRadiusSmall: {
    borderRadius: constants.borderRadius / 4,
  },
  borderRadiusHalf: {
    borderRadius: constants.borderRadius / 2,
  },
  borderRadiusDouble: {
    borderRadius: constants.borderRadius * 2,
  },
};

export default globalStyleBorders;
