import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // TODO: get lng variable from redux later on
    debug: true,
    keySeparator: false,
    nsSeparator: false,
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: { translation: require('./assets/i18n/en.json') },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
