const globalStyleFlex = {
  flex: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  displayFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  displayFlexColumnReverse: {
    flexDirection: 'column-reverse',
  },
  displayFlexWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  alignSelfStart: {
    alignSelf: 'start',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfEnd: {
    alignSelf: 'end',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  justifyContentStart: {
    justifyContent: 'flex-start',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  justifyContentSpaceAround: {
    justifyContent: 'space-around',
  },
  justifyContentSpaceEvenly: {
    justifyContent: 'space-evenly',
  },
  widthFull: {
    width: '100%',
  },
  widthHalf: {
    width: '50%',
  },
  widthThird: {
    width: '33.33%',
  },
  widthQuarter: {
    width: '25%',
  },
  widthFifth: {
    width: '20%',
  },
  flex_1: {
    flex: 1,
  },
  flex_2: {
    flex: 2,
  },
  flex_3: {
    flex: 3,
  },
  flex_4: {
    flex: 4,
  },
};

export default globalStyleFlex;
