if (!CSS.supports('height: 100dvh')) {
  document.documentElement.style.setProperty('--window-inner-height-px', `${window.innerHeight}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty(
      '--window-inner-height-px',
      `${window.innerHeight}px`,
    );
  });
}

let displayMode = 'browser';
const mqStandAlone = '(display-mode: standalone)';
if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
  displayMode = 'standalone';
}

export default {
  gridGutterWidth: 8,
  borderWidth: 1,
  borderRadius: 16,
  borderRadiusLarge: 30,
  screenContainerMaxWidth: 800,
  screenContainerPaddingHorizontal: 8,
  heightViewportFull: CSS.supports('height: 100dvh')
    ? displayMode === 'browser'
      ? 'calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom))'
      : '100dvh'
    : 'var(--window-inner-height-px)',
};
