import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { GetAllStoriesResponse } from '../models/Stories.model';

export const storiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStories: builder.mutation<GetAllStoriesResponse[], any>({
      query: () => ({
        url: Endpoints.stories,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getStoryStatus: builder.mutation<any, any>({
      query: ({ author, name }) => ({
        url: Endpoints.storyStatus,
        method: 'POST',
        body: {
          author,
          name,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    flagStory: builder.mutation<any, any>({
      query: ({ flag, nid }) => ({
        url: Endpoints.flagStories,
        method: 'POST',
        body: {
          flag,
          nid,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    storySubmissions: builder.mutation<any, any>({
      query: ({ webFormID }) => ({
        url: `${Endpoints.storySubmissions}/${webFormID}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStoriesMutation,
  useGetStoryStatusMutation,
  useFlagStoryMutation,
  useStorySubmissionsMutation,
} = storiesApi;
