import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { UserDataModel } from '../../../models/User.model';
import { logoutUser, refreshToken } from './extraSlice';

type AuthState = {
  token: string | null;
  refreshToken: string | null;
  consumerId: string | null;
  userData: UserDataModel | null;
  justLoggedOut: boolean;
};

const getInitialState = (): AuthState => ({
  token: null,
  refreshToken: null,
  consumerId: null,
  userData: null,
  justLoggedOut: false,
});

const slice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    updateEnrollYoti: (state, action) => {
      if (state.userData) {
        state.userData.enrollYoti = action.payload;
      }
    },
    updateAgeVerificationStep: (state, action) => {
      if (state.userData) {
        state.userData.ageVerificationStep = action.payload;
      }
    },
    updateAgeVerified: (state, action) => {
      if (state.userData) {
        state.userData.ageVerified = action.payload;
      }
    },
    updateYotiSessions: (state, action) => {
      if (state.userData) {
        state.userData.yotiSessions = action.payload;
      }
    },
    updateJustLoggedOut: (state, action) => {
      state.justLoggedOut = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return { ...getInitialState(), justLoggedOut: true };
    });
    builder.addCase(
      refreshToken,
      (state, { payload = { access_token: '', refresh_token: '' } }) => {
        state.token = payload.access_token;
        state.refreshToken = payload.refresh_token;
      },
    );
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.token = payload.access_token;
      state.refreshToken = payload.refresh_token;
      state.consumerId = payload.consumerId;
    });

    builder.addMatcher(api.endpoints.fetchUserData.matchFulfilled, (state, { payload }) => {
      state.userData = { ...payload };
    });
  },
});

export const {
  updateEnrollYoti,
  updateAgeVerificationStep,
  updateAgeVerified,
  updateYotiSessions,
  updateJustLoggedOut,
} = slice.actions;

export default slice.reducer;
