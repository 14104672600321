import { StackScreenProps } from '@react-navigation/stack';
import React, { ComponentType, lazy, memo, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import AuthNavigationContainer, { isAuthReady } from '../../../navigation/AuthNavigationContainer';
import { RootState } from '../../store';
import { BATLazyLoading } from './BATLazy';
import { Factory, screenFactory } from './screenFactory';
import AgeVerificationContainer from '../../../navigation/AgeVerificationContainer';

export const protectedLazyScreen = <T extends ComponentType<any>>(factory: Factory<T>) => {
  const Component = lazy(() => screenFactory(factory));

  return memo<StackScreenProps<any, string>>(function ProtectedLazyScreen(props) {
    const token = useSelector((state: RootState) => state.auth.token);
    const userData = useSelector((state: RootState) => state?.auth?.userData);
    const enrollYoti = useSelector((state: RootState) => state?.auth?.userData?.enrollYoti);
    const enrollYotiDeadline = useSelector(
      (state: RootState) => state?.auth?.userData?.enrollYotiDeadline,
    );
    const ageVerified = useSelector((state: RootState) => state?.auth?.userData?.ageVerified);

    const fallback = useMemo(() => <BATLazyLoading />, []);

    if (!isAuthReady(token) || !userData) {
      if (token === null) {
        localStorage.setItem('virtualPageLocation', window.location.href);
      }
      return <AuthNavigationContainer />;
    }

    const enrollYotiDeadlineExceeded =
      enrollYotiDeadline !== null && moment().isAfter(moment(`${enrollYotiDeadline}T23:59:59`));
    if (enrollYoti === 1 && ageVerified === 0 && enrollYotiDeadlineExceeded) {
      return <AgeVerificationContainer />;
    }

    const redirectUrl = window.localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
      window.location.href = redirectUrl;
      return;
    }

    return (
      <Suspense fallback={fallback}>
        {/* @ts-expect-error */}
        <Component {...props} />
      </Suspense>
    );
  });
};
