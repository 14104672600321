import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLinkTo } from '@react-navigation/native';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { updateGeneralMessageDialogVisibility } from '../slices/appSettingsSlice';
import { RootState } from '../store';
import BATDialogConfirmation from './common/BATDialogConfirmation';

export default memo(function BATGeneralMessageReconsent() {
  const dateGeneralMessageReconsentWasClosed = useSelector(
    (state: RootState) => state.appSettings.dateGeneralMessageReconsentWasClosed,
  );
  const month24AnonymizeDate = useSelector(
    (state: RootState) => state.auth.userData?.month24AnonymizeDate,
  );
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dateGeneralMessageReconsentWasClosedRef = useRef(dateGeneralMessageReconsentWasClosed);
  dateGeneralMessageReconsentWasClosedRef.current = dateGeneralMessageReconsentWasClosed;
  const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const numberOfDaysAvailableForReconsent = moment(month24AnonymizeDate)
    .startOf('day')
    .diff(moment().startOf('day'), 'days');

  const onCheck = () => {
    if (dateGeneralMessageReconsentWasClosedRef.current !== 0) {
      const timeDiff = Date.now() - dateGeneralMessageReconsentWasClosedRef.current;

      if (timeDiff > fiveDaysInMilliseconds) {
        dispatch(updateGeneralMessageDialogVisibility(0));
      }
    }
  };

  const onCloseDialog = useCallback(() => {
    dispatch(updateGeneralMessageDialogVisibility(Date.now()));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      onCheck();
    }, oneDayInMilliseconds);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    onCheck();
  }, []);

  return (
    <BATDialogConfirmation
      bodyTitle={t('USER_RECONSENT_TITLE')}
      bodyText={
        <Trans
          i18nKey="USER_RECONSENT_TEXT"
          values={{
            numberOfDaysAvailableForReconsent: numberOfDaysAvailableForReconsent,
          }}
        />
      }
      icon="info"
      visible={
        dateGeneralMessageReconsentWasClosed === 0 ||
        dateGeneralMessageReconsentWasClosed === undefined
      }
      buttonRightText={t('USER_RECONSENT_RENEW_AGREEMENT')}
      onHandleRight={() => {
        onCloseDialog();
        linkTo('/');
        linkTo(`/profile/privacy?redirectTo=appStack&reconsent=true`);
      }}
      onDismiss={() => {
        onCloseDialog();
      }}
    />
  );
});
