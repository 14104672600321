const ScreenNames = {
  // user
  userStack: 'userStack',
  login: 'login',
  signUp: 'sign-up',
  verificationCode: 'verification-code-screen',
  gdprCompliance: 'gdpr-compliance',
  changePassword: 'change-password',
  emailRegisterConfirm: 'email-sent',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  successRegistration: 'email-validation',

  userMigration: 'migration',
  personalDetails: 'personal-details',
  help: 'help',
  privacy: 'privacy',
  articles: 'articles',
  articleView: 'article-view',

  // app
  appStack: 'appStack',
  feed: 'feed',
  products: 'products',
  kentModeInfinite2: 'kent-mode-infinite-possibilities-2',
  gloHyperProProduct: 'glo-hyper-pro-product',
  insertPackCode: 'insertPackCode',
  insertBonusCode: 'insertBonusCode',
  insertBonusCodeSuccess: 'insertBonusCodeSuccess',
  quizzesList: 'quizzes',
  productCategories: 'product-categories',
  productDetails: 'product-details',
  prizes: 'prizes',
  wallet: 'wallet',
  articleList: 'article-list',
  productArticleList: 'product-list',
  prizeList: 'prize-list',
  prizeView: 'prize-view',
  momentView: 'moment-view',
  bundleView: 'bundle-view',
  profile: 'profile',
  inviteFriend: 'invite-friend',
  contactUs: 'contact-us',
  faq: 'faq',
  surveySuccess: 'survey-success',
  helpfulInformation: 'helpful-information',
  cookiesSettings: 'cookies-settings',
  survey: 'survey',
  recommendedArticles: 'recommended-articles',
  historyPoints: 'history-points',
  notification: 'notification',
  howToEarnPoints: 'how-to-earn-points',
  stories: 'stories',
  onBoarding: 'onboarding',
  redeemCheckout: 'redeem-checkout',
  successPrizeRedeem: 'success-prize-redeem',
  pdfs: 'pdfs',
  winPrizesPdfs: 'win-prizes-pdfs',
  viewer: 'viewer',
  campaignsList: 'campaigns',
  campaignDetailsHub: 'campaign-details-hub',
  campaignKentQ4NcpSpinTheWheelGame: 'campaign-kent-q4-ncp-spin-the-wheel-game',
  campaignGalleryList: 'campaign-gallery',
  campaignUpload: 'campaign-upload',
  favorites: 'favorites',
  devices: 'devices',
  unlockedOffer: 'unlocked-offer',

  // age verification
  ageVerificationStack: 'age-verification',
  verificationFailed: 'verification-failed',
  verificationSucceed: 'verification-succeed',
  startScreen: 'start-screen',
  faceCapture: 'face-capture',
  scanID: 'scan-id',
  verificationMandatory: 'verification-mandatory',

  allSearchResults: 'all-search-results',

  //collections
  collections: 'collections',

  pallMallArticle: 'pall-mall-article',
  tasteProfile: 'taste-profile',
};

export { ScreenNames };
