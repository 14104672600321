import to from 'await-to-js';
import { BATLazyError } from './BATLazy';
import React, { memo, useEffect } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { getParams } from '../../../utils/urlUtils';

export type Factory<T> = () => Promise<{ default: T }>;

const key = '__refreshed_on_chunk_error__';
const value = 'true';

function errorWrapper<T extends React.ComponentClass<any>>(Component: T) {
  return memo<StackScreenProps<any, string>>(function Wrapper(props) {
    useEffect(() => {
      (async () => {
        const url = new URL(window.location.href);
        const params = getParams(url.search);
        if (params[key] === value) return;
        const newUrl = new URL(window.location.pathname, window.location.origin);
        Object.entries(params).forEach((e) => {
          newUrl.searchParams.append(...e);
        });
        newUrl.searchParams.append(key, value);
        window.history.replaceState({}, 'OneUp', newUrl.href);
        window.location.reload();
      })();
    }, [props.navigation]);

    return <Component {...props} message="" />;
  });
}

function responseWrapper<T extends React.ComponentClass<any>>(Component: T) {
  return memo<StackScreenProps<any, string>>(function Wrapper(props) {
    useEffect(() => {
      (async () => {
        const url = new URL(window.location.href);
        const params = getParams(url.search);
        if (params[key] !== value) return;
        const newUrl = new URL(window.location.pathname, window.location.origin);
        delete params[key];
        Object.entries(params).forEach((e) => {
          newUrl.searchParams.append(...e);
        });
        window.history.replaceState({}, 'OneUp', newUrl.href);
      })();
    }, []);

    return <Component {...props} />;
  });
}

export async function screenFactory<T extends React.ComponentClass<any>>(
  factory: Factory<T>,
  ErrorComponent: React.ComponentType = BATLazyError,
): Promise<{ default: T }> {
  const [error, response] = await to(factory());
  if (error != null || response == null) {
    console.error('screenFactory error', error);
    // @ts-expect-error
    return { default: errorWrapper(ErrorComponent) };
  }
  return { default: responseWrapper(response.default) as any };
}
