export const brandDetails = [
  {
    name: 'glo',
    color: '#E8603E',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/glo-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/glo-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/glo-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/glo-middle-bottom-left.png'),
    productCollectionTitle: 'glo™ Universe',
    productCollectionDescription:
      'Universul glo™ este în continuă schimbare! Fii mereu conectat la seria de update-uri despre dispozitive, consumabile și accesorii pentru o experiență way better.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/glo_prod_article_collection.jpg'),
  },
  {
    name: 'Vuse',
    color: '#AE4283',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/vuse-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/vuse-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/vuse-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/vuse-middle-bottom-left.png'),
    productCollectionTitle: 'Vuse World',
    productCollectionDescription:
      'De la arome la noi funcționalități, fii la curent cu cele mai recente noutăți și informații despre dispozitivele tale preferate Vuse.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/vuse_prod_article_collection.jpg'),
  },
  {
    name: 'Dunhill',
    color: '#BAA066',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/dunhill-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/dunhill-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/dunhill-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/dunhill-middle-bottom-left.png'),
    productCollectionTitle: 'Our Refinement Portfolio',
    productCollectionDescription:
      'Reconectează-te cu stilul sofisticat cu o serie de articole ce aprofundează eleganța gamelor Dunhill și atenția primită de fiecare detaliu, de la design la experiență.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/dunhill_prod_article_collection.jpg'),
  },
  {
    name: 'Kent',
    color: '#2B2BFD',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/kent-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/kent-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/kent-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/kent-middle-bottom-left.png'),
    productCollectionTitle: 'Our Visionary Range',
    productCollectionDescription:
      'Descoperă viziunea care îți inspiră progresul de zi cu zi într-o serie de articole dedicate portofoliului de produse Kent.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/kent_prod_article_collection.jpg'),
  },
  {
    name: 'Pall Mall',
    color: '#4AA8FF',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/pall-mall-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/pall-mall-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/pall-mall-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/pall-mall-middle-bottom-left.png'),
    productCollectionTitle: 'Seria Pall Mall',
    productCollectionDescription:
      'Află totul despre gașca de produse Pall Mall din aceste articole informative. Parcurge-le relaxat și vezi că de multe ori afli ce și cum poți câștiga. ',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/pall-mall_prod_article_collection.jpg'),
  },
  {
    name: 'VELO',
    color: '#008AAC',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/velo-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/velo-bottom.png'),
    hideFromList: true,
    productCollectionTitle: 'Weirdly Wonderful News',
    productCollectionDescription:
      'Toate noutățile VELO în același loc, Weird. Ești mereu printre primii care le află, Wonderful.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/velo_prod_article_collection.jpg'),
  },
  {
    name: 'Vogue',
    color: '#C69492',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/vogue-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/vogue-bottom.png'),
    hideFromList: true,
    productCollectionTitle: 'Rafinamentul Vogue',
    productCollectionDescription:
      'Locul special unde descoperi cele mai noi informații despre produsele tale Vogue favorite.',
    productCollectionImage: require('../assets/filters-logo-brand-personalizations/vogue_prod_article_collection.jpg'),
  },
];

export const orderBrands = (brands: string[]) => {
  brands.sort((a, b) => {
    return (
      brandDetails.findIndex((brand) => brand.name === a) -
      brandDetails.findIndex((brand) => brand.name === b)
    );
  });

  return brands;
};

export const transformFromUrlIntoBrandName = (brandUrl: string) => {
  const formattedUrl = brandUrl.toLowerCase().trim().replaceAll('-', ' ');
  const matchedBrand = brandDetails.find((brand) =>
    formattedUrl.includes(brand.name.toLowerCase()),
  );
  return matchedBrand?.name || '';
};
