import { StyleSheet } from 'react-native';
import globalStyleBorders from './shared/borders';
import colors from './shared/colors';
import globalStyleFlex from './shared/flex';
import globalStyleGeneral from './shared/general';
import globalStyleMargins from './shared/margins';
import constants from './shared/constants';
import globalStyleZIndex from './shared/zIndex';

// @ts-expect-error
export const sharedStyles = StyleSheet.create({
  //general styles
  button: {
    height: 50,
    justifyContent: 'center',
  },
  iconContainer: {
    padding: 10,
    borderRadius: 50,
    marginRight: 11,
  },
  batIconSuccess: {
    backgroundColor: colors.successBackgroundWeak,
    borderRadius: 50,
  },
  batIconWarning: {
    backgroundColor: colors.warningBackgroundWeak,
    borderRadius: 50,
  },
  batIconDanger: {
    backgroundColor: colors.errorBackgroundWeak,
    borderRadius: 50,
  },
  input: {
    height: 48,
    fontSize: 16,
    lineHeight: 22,
  },
  screenContainer: {
    maxWidth: constants.screenContainerMaxWidth,
    width: '100%',
    paddingHorizontal: constants.screenContainerPaddingHorizontal,
    alignSelf: 'center',
  },
  scrollViewBottomSpace: {
    paddingBottom: 75,
  },
  scrollViewBottomSpace2: {
    paddingBottom: 250,
  },
  backPageHeader: {
    position: 'relative',
    height: 80,
    marginBottom: 30,
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: 16,
    minHeight: 48,
    width: '100%',
    padding: 8,
    backgroundColor: colors.card_background_lighter,
    borderColor: colors.card_border_lighter,
    borderWidth: 1,
    borderRadius: 8,
    overflow: 'hidden',
  },

  //Forms Container
  formContainer: {
    flex: 1,
    maxWidth: 800,
  },

  //spacers
  marginBottomSpacer: {
    marginBottom: 24,
  },

  //typography
  linkStyle: {
    fontSize: 16,
    color: colors.primary,
  },
  screenTitle: {
    fontSize: 32,
    lineHeight: 32,
    fontWeight: '700',
    marginBottom: 24,
  },
  surveyTitle: {
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: -0.02,
    color: colors.text,
    marginTop: 0,
  },
  campaignTitle: {
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: -0.02,
    color: colors.white_50,
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 8,
  },
  campaignSubtitle: {
    fontSize: 16,
    lineHeight: 21,
    letterSpacing: -0.02,
    opacity: 1,
    color: colors.text,
  },
  campaignText: {
    lineHeight: 22,
    letterSpacing: 0.35,
    opacity: 0.7,
  },
  flexTitleContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 24,
    marginBottom: 8,
  },
  titleStyle: {
    fontSize: 24,
    lineHeight: 32,
    marginTop: 16,
    marginBottom: 0,
    letterSpacing: '-0.02em',
  },
  //Prize
  prizeIconLabel: {
    letterSpacing: -0.41,
    color: colors.text,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
  },

  //Products
  cardButton: {
    color: colors.primary,
    fontSize: 14,
    lineHeight: 17,
    letterSpacing: -0.02,
    display: 'flex',
    alignItems: 'center',
  },
  cardButtonIcon: {
    marginLeft: 6,
  },
  ...globalStyleBorders,
  ...globalStyleGeneral,
  ...globalStyleFlex,
  ...globalStyleMargins,
  ...globalStyleZIndex,
});
