import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ScreenNames } from '../screens/ScreenNames';
import { protectedLazyScreenAgeVerification } from '../src/components/Lazy/protectedLazyScreenAgeVerification';

const StartScreen = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/StartScreen'),
);
const FaceCapture = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/FaceCaptureScreen'),
);
const ScanID = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/ScanID'),
);
const VerificationFailed = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/VerificationFailed'),
);
const VerificationSucceed = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/VerificationSucceed'),
);
const VerificationMandatory = protectedLazyScreenAgeVerification(
  () => import('../screens/AgeVerification/VerificationMandatory'),
);

const Stack = createStackNavigator();

const AgeVerificationFlow = () => {
  return (
    <Stack.Navigator initialRouteName={ScreenNames.verificationMandatory}>
      <Stack.Screen
        name={ScreenNames.startScreen}
        key={ScreenNames.startScreen}
        options={{ headerShown: false }}
        component={StartScreen}
      />
      <Stack.Screen
        name={ScreenNames.faceCapture}
        key={ScreenNames.faceCapture}
        options={{ headerShown: false }}
        component={FaceCapture}
      />
      <Stack.Screen
        name={ScreenNames.scanID}
        key={ScreenNames.scanID}
        options={{ headerShown: false }}
        component={ScanID}
      />
      <Stack.Screen
        name={ScreenNames.verificationFailed}
        key={ScreenNames.verificationFailed}
        options={{ headerShown: false }}
        component={VerificationFailed}
      />
      <Stack.Screen
        name={ScreenNames.verificationSucceed}
        key={ScreenNames.verificationSucceed}
        options={{ headerShown: false }}
        component={VerificationSucceed}
      />
      <Stack.Screen
        name={ScreenNames.verificationMandatory}
        key={ScreenNames.verificationMandatory}
        options={{ headerShown: false }}
        component={VerificationMandatory}
      />
    </Stack.Navigator>
  );
};

export default AgeVerificationFlow;
