import { View, StyleSheet, SafeAreaView } from 'react-native';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../store';
import BATText from './BATText';
import BATIcon from '../BATIcon';
import BATButtonNew from './BATButtonNew';
import colors from '../../../config/theme/shared/colors';
import { sharedStyles } from '../../../config/theme/shared.styles';

const BATAnnounceToast = ({
  text1,
  text2,
  onPress,
  onHide,
}: {
  text1: string;
  text2?: string;
  onPress: () => void;
  onHide: () => void;
}) => {
  const screenType = useSelector((state: RootState) => state.appSettings.deviceScreenType);

  const { t } = useTranslation();

  const handleOnPress = () => {
    onHide();
    onPress();
  };

  return (
    <SafeAreaView style={[sharedStyles.heightViewportFull, sharedStyles.relative]}>
      <View
        style={[
          sharedStyles.widthFull,
          sharedStyles.alignItemsCenter,
          sharedStyles.absolute,
          screenType === 'mobile' ? { bottom: 160 } : { top: 10 },
        ]}>
        <View
          style={[
            { maxWidth: '90vw', width: 480 },
            styles.box,
            sharedStyles.borderRadiusHalf,
            sharedStyles.padding,
          ]}>
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <View>
              <BATIcon name="notification-icon" size={24} color={colors.grey_text} />
            </View>
            <View style={{ flex: 1, alignItems: 'flex-start' }}>
              <BATText color={colors.grey_title}>{text1}</BATText>
              <BATText type="body-small">{text2}</BATText>
              <View style={[sharedStyles.marginTop, { paddingVertical: 4 }]}>
                <BATButtonNew
                  size="small"
                  variant="outlined"
                  handleSubmitButton={handleOnPress}
                  customStyle={{ color: colors.violet }}>
                  {t('VIEW_DETAILS')}
                </BATButtonNew>
              </View>
            </View>
            <View>
              <BATIcon
                name="close"
                size={16}
                color={colors.grey_text}
                onPress={onHide}
                style={sharedStyles.paddingHalf}
              />
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};
export default BATAnnounceToast;

const styles = StyleSheet.create({
  box: {
    borderLeftWidth: 4,
    borderLeftColor: colors.toastAnnounce,
    backgroundColor: colors.card_background_lighter,
  },
});
