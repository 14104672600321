import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

import { logoutUser } from '../../Authentication/slices/extraSlice';
import { CardProps } from '../../../src/components/BATCard';
import { ArticleModel, RelatedArticlesModel } from '../../../models/Article.model';
import { ARTICLE_TYPE } from '../../../models/Feed.model';

interface Collection {
  title: string;
  image: string;
  description: string;
  isAuthorCollection: boolean;
}

function isCardProps(obj: any): obj is CardProps {
  const requiredKeys: (keyof CardProps)[] = [
    'nid',
    'category',
    'image',
    'title',
    'brand',
    'authorName',
    'authorImage',
    'created',
  ];

  if (!obj || typeof obj !== 'object') {
    return false;
  }

  for (const key of requiredKeys) {
    if (!(key in obj)) {
      return false;
    }
  }

  return true;
}

const formatDate = (date: string) => {
  if (/^\w{3}, \d{2}\/\d{2}\/\d{4} - \d{2}:\d{2}$/.test(date)) {
    const datePart = date.split(' - ')[0].split(', ')[1];
    return moment(datePart, 'MM/DD/YYYY').locale('ro').format('DD MMM YYYY');
  } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    return moment(date, 'DD/MM/YYYY').locale('ro').format('DD MMM YYYY');
  }
  return '';
};

const convertToFormattedDate = (dateString: string) => {
  const isDateFormat1 = /^(\w{3}, )?\d{2}\/\d{2}\/\d{4} - \d{2}:\d{2}$/.test(dateString);

  if (isDateFormat1) {
    return moment(dateString, 'ddd, MM/DD/YYYY - HH:mm').format('DD/MM/YYYY');
  }
  return dateString;
};

export const formatArticleAsCardProps = (
  article: any,
  territorySlug?: string,
  isAuthorCollection?: boolean,
): CardProps => {
  return {
    nid: article.nid,
    image: isAuthorCollection ? article.thumbnail__target_id : article.field_image,
    category: article.field_content_territory,
    title: article.title,
    date: formatDate(article.created),
    brand: article.field_brand,
    authorName: article.field_author,
    authorImage: article.author_image,
    url_alias: `/articole${article.brand_url_alias ?? `/${article.field_brand}`}/${
      article?.article_type === ARTICLE_TYPE.product
        ? 'produs'
        : territorySlug || article?.url_alias
    }${article.url_alias}`,
    badge:
      article.is_read === 'No' || Number(article.is_read) === 0
        ? {
            label:
              Number(article.field_points) > 0
                ? Number(article.field_points) === 1
                  ? '1 Punct'
                  : `${Number(article.field_points)} Puncte`
                : undefined,
            variant: 'points',
          }
        : undefined,
    created: convertToFormattedDate(article.created),
    isRead: article.is_read === '1',
    isFavorite: Number(article.is_favorite) === 1,
  };
};

const getInitialState = () => ({
  collections: {} as Record<string, Collection>,
  collectionArticles: {} as Record<
    string,
    { [key: string]: { articles: CardProps[]; maxPages: number; articlesCount: number } }
  >,
  articles: {} as Record<
    string,
    {
      article: ArticleModel;
      relatedArticles: CardProps[];
    }
  >,
});

const articlesSlice = createSlice({
  name: 'articles',
  initialState: getInitialState(),
  reducers: {
    setCollectionDetails: (state, action) => {
      const collectionDetails = action.payload.data[0];
      const territorySlug = action.payload.territorySlug;
      const isAuthorCollection = action.payload.isAuthorCollection;

      state.collections[territorySlug] = {
        title: collectionDetails.name,
        image: isAuthorCollection ? collectionDetails.cover_image : collectionDetails.field_image,
        description: collectionDetails.description__value,
        isAuthorCollection: isAuthorCollection,
      };

      state.collectionArticles[territorySlug] = {};
    },
    setArticles: (state, action) => {
      const territorySlug = action.payload.territorySlug;
      const filter = action.payload.filter ?? 'all';
      const isAuthorCollection = action.payload.isAuthorCollection;

      const articles = action.payload.data;
      const maxPages = action.payload.maxPages;
      const articlesCount = action.payload.articlesCount;

      state.collectionArticles[territorySlug][filter] = {
        articles: [],
        maxPages: maxPages ?? Infinity,
        articlesCount: articlesCount ?? Infinity,
      };

      articles.forEach((article: any) => {
        if (isCardProps(article)) {
          state.collectionArticles[territorySlug][filter].articles.push(article);
        } else {
          state.collectionArticles[territorySlug][filter].articles.push({
            ...formatArticleAsCardProps(article, territorySlug, isAuthorCollection),
          });
        }
      });
    },
    toggleArticleFavoriteState: (state, action) => {
      const entityId = action.payload.entityId;
      const isFavorite = action.payload.isFavorite;
      const territorySlug = action.payload.entityData?.territory_url_alias?.replace('/', '');

      if (territorySlug && state.collectionArticles[territorySlug]) {
        const articles = state.collectionArticles[territorySlug];
        Object.keys(articles).forEach((filter) => {
          state.collectionArticles[territorySlug][filter].articles = articles[filter].articles.map(
            (article) => {
              if (article.nid === entityId) {
                return { ...article, isFavorite };
              }
              return article;
            },
          );
        });
      }
    },
    toggleArticleReadState: (state, action) => {
      const entityId = action.payload.entityId;
      const territorySlug = action.payload.territorySlug;

      if (territorySlug && state.collectionArticles[territorySlug]) {
        const articles = state.collectionArticles[territorySlug];
        Object.keys(articles).forEach((filter) => {
          state.collectionArticles[territorySlug][filter].articles = articles[filter].articles.map(
            (article) => {
              if (article.nid === entityId) {
                return { ...article, badge: undefined, isRead: true };
              }
              return article;
            },
          );
        });
      }
    },
    setArticleDetails: (state, action) => {
      const articleSlug = action.payload.articleSlug;
      state.articles[articleSlug] = {
        ...state.articles[articleSlug],
        article: action.payload.article,
      };
    },
    setRelatedArticles: (state, action) => {
      const articleSlug = action.payload.articleSlug;
      const relatedArticles = action.payload.relatedArticles ?? [];

      const formattedArticles = relatedArticles.map((article: RelatedArticlesModel) =>
        formatArticleAsCardProps(article),
      );

      state.articles[articleSlug] = {
        ...state.articles[articleSlug],
        relatedArticles: formattedArticles,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const {
  setCollectionDetails,
  setArticles,
  toggleArticleFavoriteState,
  toggleArticleReadState,
  setArticleDetails,
  setRelatedArticles,
} = articlesSlice.actions;
export default articlesSlice.reducer;
