import {
  ArticleModel,
  Author,
  GetArticlesModel,
  GetRelatedArticlesSimpleRequest,
  GetRelatedArticlesSimpleResponse,
  RelatedArticlesRangeList,
} from './../models/Article.model';
import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { PagerModel } from '../models/common';

export const articlesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.mutation<GetArticlesModel, any>({
      query: ({
        territorySlug,
        params,
      }: {
        territorySlug: string;
        params: { page: number; filter: string };
      }) => {
        let url = `${Endpoints.getArticles}/${territorySlug}?page=${params?.page}${
          params?.filter ? `&${params?.filter}` : ''
        }`;

        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getArticle: builder.mutation<ArticleModel, any>({
      query: (articleSlug) => ({
        url: `${Endpoints.getArticle}/${articleSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
      transformResponse: (response: any) => response?.[0],
    }),
    getRelatedArticlesSimple: builder.mutation<
      GetRelatedArticlesSimpleResponse,
      GetRelatedArticlesSimpleRequest
    >({
      query: ({ territorySlug, articleSlug, campaignSlug, page, items_per_page }) => {
        return {
          url: `${Endpoints.getRelatedArticlesSimple}/${territorySlug}/${
            articleSlug || campaignSlug
          }`,
          method: 'GET',
          params: {
            page,
            items_per_page,
          },
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getRelatedArticlesByProductRange: builder.mutation<
      { results: RelatedArticlesRangeList[]; pager: PagerModel },
      any
    >({
      query: ({ categorySlug, page }) => {
        return {
          url: `${Endpoints.getRelatedArticlesByProductRange}/${categorySlug}`,
          method: 'GET',
          params: { page },
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getRelatedArticlesByBrandId: builder.mutation<
      { results: RelatedArticlesRangeList[]; pager: PagerModel },
      any
    >({
      query: ({ brand, page, params }) => {
        let url = `${Endpoints.getRelatedArticlesByBrandId}/${brand}?page=${page}${
          params?.filter ? `&${params?.filter}` : ''
        }${params?.sort ? `&${params?.sort}` : ''}`;
        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    articleEarningPoints: builder.mutation<any, any>({
      query: ({ articleSlug }) => ({
        url: `${Endpoints.articleEarnPoints}/${articleSlug}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getRelatedArticlesProducts: builder.mutation<any, any>({
      query: ({ brandSlug, articleSlug, page, items_per_page }) => {
        return {
          url: `${Endpoints.getRelatedArticlesProducts}/${brandSlug}/${articleSlug}`,
          method: 'GET',
          params: {
            page,
            items_per_page,
          },
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getCollectionDetails: builder.mutation<any, any>({
      query: ({ territory_id }) => {
        return {
          url: `${Endpoints.getContentTerritories}/${territory_id}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getAuthors: builder.mutation<Author[], {}>({
      query: () => {
        return {
          url: Endpoints.authors,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getAuthorDetails: builder.mutation<any, any>({
      query: ({ authorName }) => {
        return {
          url: `${Endpoints.authors}/${authorName}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getAuthorDetailsById: builder.mutation<any, any>({
      query: ({ authorId }) => {
        return {
          url: `${Endpoints.authors}/${authorId}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
    getAuthorArticles: builder.mutation<GetArticlesModel, any>({
      query: ({
        authorName,
        params,
      }: {
        authorName: string;
        params: { page: number; filter: string };
      }) => {
        let url = `${Endpoints.authorArticles}/${authorName}?page=${params?.page}${
          params?.filter ? `&${params?.filter}` : ''
        }`;

        return {
          url,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetArticlesMutation,
  useGetArticleMutation,
  useGetRelatedArticlesSimpleMutation,
  useGetRelatedArticlesProductsMutation,
  useArticleEarningPointsMutation,
  useGetRelatedArticlesByProductRangeMutation,
  useGetRelatedArticlesByBrandIdMutation,
  useGetCollectionDetailsMutation,
  useGetAuthorsMutation,
  useGetAuthorDetailsMutation,
  useGetAuthorDetailsByIdMutation,
  useGetAuthorArticlesMutation,
} = articlesApi;
