import {
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import 'react-native-gesture-handler';
import RootNavigatorFlow from './navigation/RootNavigatorFlow';
import { Provider as PaperProvider } from 'react-native-paper';
import { darkTheme } from './config/theme';
import * as SplashScreen from 'expo-splash-screen';
import { store, persistor } from './src/store';
import { Provider } from 'react-redux';
import * as cookieRegistration from './src/cookieRegistration';
import * as recaptchaRegistration from './src/recaptchaRegistration';
import { initOneSignal } from './src/one-signal';
import {
  useFonts,
  Rubik_300Light,
  Rubik_400Regular,
  Rubik_500Medium,
  Rubik_600SemiBold,
  Rubik_700Bold,
} from '@expo-google-fonts/rubik';
import {
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
} from '@expo-google-fonts/roboto';
import AppLoading from 'expo-app-loading';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';
import { createURL } from 'expo-linking';
import { LinkingConfig } from './navigation/LinkingConfig';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import Toast from 'react-native-toast-message';
import { toastConfig } from './config/theme/toastConfig';
import { Platform } from 'react-native';
import { pushGAEvent } from './utils/gaUtils';
import { useTranslation } from 'react-i18next';
import { enableLegacyWebImplementation } from 'react-native-gesture-handler';
import BATAnnouncements from './src/components/announcements/BATAnnouncements';
import BATGeneralMessageReconsent from './src/components/BATGeneralMessageReconsent';
import moment from 'moment';
import redirects from './redirects.json';

export default function App() {
  const navRef = useNavigationContainerRef();
  const routeNameRef: any = useRef();

  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [month24AnonymizeDate, setMonth24AnonymizeDate] = useState();

  const { t } = useTranslation();

  let [fontsLoaded] = useFonts({
    Rubik_300Light,
    Rubik_400Regular,
    Rubik_500Medium,
    Rubik_600SemiBold,
    Rubik_700Bold,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    IcoMoon: require('./assets/icons/icomoon.ttf'),
  });

  const daysUntilShowUserReconsent = 45;
  const showUserReconsent =
    !!month24AnonymizeDate &&
    moment(month24AnonymizeDate).startOf('day').diff(moment().startOf('day'), 'days') <=
      daysUntilShowUserReconsent;

  enableLegacyWebImplementation(true);

  useEffect(() => {
    initOneSignal();
    SplashScreen.preventAutoHideAsync().then();
  }, []);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  // deep linking
  const linking: LinkingOptions<{}> = {
    prefixes: [createURL('/')],
    config: LinkingConfig,
  };

  return (
    <Provider store={store}>
      <PaperProvider theme={darkTheme}>
        <PersistGate loading={null} persistor={persistor}>
          <BottomSheetModalProvider>
            <NavigationContainer
              ref={navRef}
              onReady={() => {
                setReady(true);

                const redirect = redirects.find(
                  ([oldPath]) => oldPath === window.location.pathname,
                );
                if (redirect) {
                  const [oldPath, newPath] = redirect;
                  if (oldPath != newPath) {
                    window.location.pathname = newPath;
                  }
                }
              }}
              theme={darkTheme}
              linking={linking}
              documentTitle={{
                formatter: (options) => options?.title ?? 'OneUp',
              }}
              onStateChange={() => {
                setLoggedIn(!!store.getState().auth.userData?.consumerId);
                setMonth24AnonymizeDate(store.getState().auth.userData?.month24AnonymizeDate);
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navRef.getCurrentRoute()?.name;
                const trackScreenView = (currentRoute: string | undefined) => {
                  pushGAEvent('datalayer_initialized', 'user', {
                    loggedInStatus: `${
                      store.getState().auth.userData?.consumerId
                        ? t('GA-LOGGED_IN_USER')
                        : t('GA-LOGGED_OUT_USER')
                    }`,
                    ...(store.getState().auth.userData?.consumerId && {
                      id: store.getState().auth.userData?.consumerId,
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      type: store.getState().auth.userData?.cluster?.type,
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      customerLtvPoints: store.getState().userPoints?.total_points_consumed
                        ? store.getState().userPoints?.total_points_consumed?.activity_consumed +
                          store.getState().userPoints?.total_points_consumed?.loyalty_consumed
                        : 0,
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      customerLtvCodes: store.getState().userPoints?.total_codes_entered ?? 0,
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      mktAgreement: store.getState().auth.userData?.consents?.marketingConsent
                        ? 'yes'
                        : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      mktSurveys: store.getState().auth.userData?.consents?.researchConsent
                        ? 'yes'
                        : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      newsletterSubscriber: store
                        .getState()
                        .auth.userData?.consents.communicationConsent.includes('Email')
                        ? 'yes'
                        : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      smsSubscriber: store
                        .getState()
                        .auth.userData?.consents?.communicationConsent.includes('SMS')
                        ? 'yes'
                        : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      phoneSubscriber: store
                        .getState()
                        .auth.userData?.consents?.communicationConsent.includes('Phone')
                        ? 'yes'
                        : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      referredMember: store.getState().auth.userData?.referred ? 'yes' : 'no',
                    }),
                    ...(store.getState().auth.userData?.consumerId && {
                      oldPlatformUser: store.getState().auth.userData?.oldPlatformUser
                        ? 'yes'
                        : 'no',
                    }),
                    platform: Platform.OS,
                    siteSection: currentRoute,
                  });
                };

                if (previousRouteName !== currentRouteName) {
                  // Save the current route name for later comparison
                  routeNameRef.current = currentRouteName;

                  // Send a GA event only if it is not appStack
                  if (currentRouteName !== 'appStack') {
                    trackScreenView(currentRouteName);
                  }
                }
              }}>
              {ready ? (
                <>
                  {loggedIn && showUserReconsent && <BATGeneralMessageReconsent />}
                  {loggedIn && <BATAnnouncements />}
                  <RootNavigatorFlow />
                </>
              ) : null}
            </NavigationContainer>
            <Toast config={toastConfig} /> {/* ref={(ref) => Toast.setRef(ref)} */}
          </BottomSheetModalProvider>
        </PersistGate>
      </PaperProvider>
    </Provider>
  );
}

cookieRegistration.register();
recaptchaRegistration.register();
