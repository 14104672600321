import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { logoutUser } from '../../Authentication/slices/extraSlice';

const getInitialState = () => ({ galleryDataDetailsPage: null });

const slice = createSlice({
  name: 'campaign',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getCampaignDetails.matchFulfilled, (state, { payload }) => {
      state.galleryDataDetailsPage = payload.sections;
    });
  },
});

export const { updateCampaignFlag } = slice.actions;

export default slice.reducer;
