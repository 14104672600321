import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { ScreenNames } from '../ScreenNames';
import * as React from 'react';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';

const Survey = protectedLazyScreen(() => import('./Survey'));
const SurveySuccess = protectedLazyScreen(() => import('./SurveysSuccess'));
const TasteProfile = protectedLazyScreen(() => import('../TasteProfile/TasteProfile'));

const Stack = createStackNavigator();

const SurveyFlow = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator initialRouteName={ScreenNames.survey}>
      <Stack.Screen
        name={ScreenNames.survey}
        key={ScreenNames.survey}
        options={{ title: t('SURVEY_TITLE'), headerShown: false }}
        component={Survey}
      />
      <Stack.Screen
        name={ScreenNames.surveySuccess}
        key={ScreenNames.surveySuccess}
        options={{ title: t('SUCCESS'), headerShown: false }}
        component={SurveySuccess}
      />
      <Stack.Screen
        name={ScreenNames.tasteProfile}
        key={ScreenNames.tasteProfile}
        options={{ title: t('TASTE_PROFILE'), headerShown: false }}
        component={TasteProfile}
      />
    </Stack.Navigator>
  );
};
export default SurveyFlow;
