import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { PromoModel } from '../models/Promo.model';

export const promosApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromos: builder.mutation<PromoModel[], any>({
      query: () => ({
        url: Endpoints.getPromos,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetPromosMutation } = promosApi;
