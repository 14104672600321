import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import React, { memo } from 'react';
import { Image, OpaqueColorValue, StyleProp, View, ViewStyle } from 'react-native';

type BATIconProps = {
  /**
   * Size of the icon, can also be passed as fontSize in the style object.
   *
   * @default 12
   */
  size?: number;
  /**
   * Name of the icon to show
   *
   * See Icon Explorer app
   * {@link https://expo.github.io/vector-icons/}
   */
  name?: string;
  /**
   * Color of the icon. Can be a string or OpaqueColorValue (returned from
   * PlatformColor(..))
   *
   */
  color?: string | OpaqueColorValue;
  src?: string;
  style?: StyleProp<ViewStyle>;
  onPress?(): void;
};

const Icon = createIconSetFromIcoMoon(
  require('../../assets/icons/selection.json'),
  'IcoMoon',
  'icomoon.ttf',
);

export default memo<BATIconProps>(function BATIcon(props) {
  if (props.src == null) {
    return <Icon {...props} />;
  }

  return (
    <View style={[props.style]}>
      <Image
        source={{ uri: props.src }}
        style={[{ width: props.size, height: props.size }]}
        resizeMode="contain"
      />
    </View>
  );
});
