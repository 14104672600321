import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import constants from '../../config/theme/shared/constants';
import api from '../../config/api';

type AppSettingsState = {
  deviceScreenType: 'mobile' | 'desktop' | null;
  dateGeneralMessageReconsentWasClosed?: number;
  reCaptchaValidationEnabled?: boolean;
};

const getInitialState = (): AppSettingsState => ({
  deviceScreenType: null,
  dateGeneralMessageReconsentWasClosed: 0,
  reCaptchaValidationEnabled: false,
});

const slice = createSlice({
  name: 'appSettings',
  initialState: getInitialState(),
  reducers: {
    updateScreenType(state, action: PayloadAction<number>) {
      if (action.payload < constants.screenContainerMaxWidth) {
        state.deviceScreenType = 'mobile';
      } else {
        state.deviceScreenType = 'desktop';
      }
    },

    updateGeneralMessageDialogVisibility(state, action: PayloadAction<number>) {
      state.dateGeneralMessageReconsentWasClosed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getReCaptchaValidationStatus.matchFulfilled,
      (state, { payload }) => {
        state.reCaptchaValidationEnabled = payload.recaptcha_checked;
      },
    );
  },
});

export const { updateScreenType, updateGeneralMessageDialogVisibility } = slice.actions;

export default slice.reducer;
