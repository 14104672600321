import { StyleSheet, Text } from 'react-native';

import { useIsMobile } from '../../hooks/useIsMobile';
import colors from '../../../config/theme/shared/colors';

export type BATTextType =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'heading-6'
  | 'button-large'
  | 'button'
  | 'button-small'
  | 'body'
  | 'body-small'
  | 'label'
  | 'caption'
  | 'tag'
  | 'tag-bold';

interface BATTextProps {
  type?: BATTextType;
  customStyle?: any;
  children: any;
  color?: string;
  numberOfLines?: number;
}

const BATText = ({
  type = 'body',
  color = colors.grey_text,
  customStyle,
  children,
  numberOfLines,
}: BATTextProps) => {
  const { isMobile } = useIsMobile();

  return (
    <Text
      style={[isMobile ? typographyMobile[type] : typographyDesktop[type], { color }, customStyle]}
      numberOfLines={numberOfLines || undefined}>
      {children}
    </Text>
  );
};

const typographyMobile = StyleSheet.create({
  'heading-1': {
    fontSize: 32,
    lineHeight: 40,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-2': {
    fontSize: 26,
    lineHeight: 32,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-3': {
    fontSize: 22,
    lineHeight: 28,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-4': {
    fontSize: 18,
    lineHeight: 24,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-5': {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-6': {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Rubik_500Medium',
  },

  'button-large': {
    fontSize: 18,
    lineHeight: 24,
    fontFamily: 'Rubik_500Medium',
  },
  button: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'Rubik_500Medium',
  },
  'button-small': {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Rubik_500Medium',
  },

  body: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
  },
  'body-small': {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto_400Regular',
  },

  label: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto_500Medium',
  },
  caption: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Roboto_400Regular',
  },
  tag: {
    fontSize: 10,
    lineHeight: 12,
    fontFamily: 'Roboto_400Regular',
  },
  'tag-bold': {
    fontSize: 10,
    lineHeight: 12,
    fontFamily: 'Roboto_700Bold',
  },
});

const typographyDesktop = StyleSheet.create({
  'heading-1': {
    fontSize: 48,
    lineHeight: 56,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-2': {
    fontSize: 36,
    lineHeight: 48,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-3': {
    fontSize: 30,
    lineHeight: 40,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-4': {
    fontSize: 24,
    lineHeight: 32,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-5': {
    fontSize: 20,
    lineHeight: 28,
    fontFamily: 'Rubik_500Medium',
  },
  'heading-6': {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'Rubik_500Medium',
  },

  'button-large': {
    fontSize: 18,
    lineHeight: 24,
    fontFamily: 'Rubik_500Medium',
  },
  button: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'Rubik_500Medium',
  },
  'button-small': {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Rubik_500Medium',
  },

  body: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: 'Roboto_400Regular',
  },
  'body-small': {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'Roboto_400Regular',
  },

  label: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto_500Medium',
  },
  caption: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'Roboto_400Regular',
  },
  tag: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Roboto_400Regular',
  },
  'tag-bold': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Roboto_700Bold',
  },
});

export default BATText;
