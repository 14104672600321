import api from '../config/api';
import Endpoints from '../config/api/endpoints';

export const favoritesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFavoritesList: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.favorites,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
      transformResponse: (response: any) => response.results,
    }),
    postFavorite: builder.mutation<any, any>({
      query: ({ entityId }) => ({
        url: `${Endpoints.favorites}/${entityId}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    deleteFavorite: builder.mutation<any, any>({
      query: ({ entityId }) => ({
        url: `${Endpoints.favorites}`,
        method: 'DELETE',
        body: { nid: entityId },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetFavoritesListMutation, usePostFavoriteMutation, useDeleteFavoriteMutation } =
  favoritesApi;
