import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { SurveyModel } from '../../../models/Survey.model';
import { logoutUser } from '../../Authentication/slices/extraSlice';

type SurveyState = {
  surveys: SurveyModel[];
  areLoaded: boolean;
};

const getInitialState = (): SurveyState => ({
  surveys: [],
  areLoaded: false,
});

const slice = createSlice({
  name: 'content',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getSurveys.matchFulfilled, (state, { payload }) => {
      state.surveys = payload;
      state.areLoaded = true;
    });
    builder.addMatcher(api.endpoints.postSurvey.matchFulfilled, (state, { payload }) => {
      state.areLoaded = false;
      if (state.surveys) {
        state.surveys = state.surveys.filter((survey) => survey.nid !== payload.nid);
        state.areLoaded = true;
      }
    });
  },
});

export default slice.reducer;
