import constants from './constants';

const globalStyleMargins = {
  margin: {
    margin: constants.gridGutterWidth,
  },
  padding: {
    padding: constants.gridGutterWidth,
  },
  marginTop: {
    marginTop: constants.gridGutterWidth,
  },
  paddingTop: {
    paddingTop: constants.gridGutterWidth,
  },
  marginBottom: {
    marginBottom: constants.gridGutterWidth,
  },
  paddingBottom: {
    paddingBottom: constants.gridGutterWidth,
  },
  marginRight: {
    marginRight: constants.gridGutterWidth,
  },
  paddingRight: {
    paddingRight: constants.gridGutterWidth,
  },
  marginLeft: {
    marginLeft: constants.gridGutterWidth,
  },
  paddingLeft: {
    paddingLeft: constants.gridGutterWidth,
  },
  marginVertical: {
    marginVertical: constants.gridGutterWidth,
  },
  paddingVertical: {
    paddingVertical: constants.gridGutterWidth,
  },
  marginHorizontal: {
    marginHorizontal: constants.gridGutterWidth,
  },
  paddingHorizontal: {
    paddingHorizontal: constants.gridGutterWidth,
  },
};

const globalStyleMarginsNegative = Object.entries(globalStyleMargins).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [`${key}Negative`]: {
      [key]: -value[key],
    },
    [`${key}Half`]: {
      [key]: value[key] / 2,
    },
    [`${key}HalfNegative`]: {
      [key]: -value[key] / 2,
    },
    [`${key}Third`]: {
      [key]: value[key] / 3,
    },
    [`${key}Quarter`]: {
      [key]: value[key] / 4,
    },
    [`${key}Double`]: {
      [key]: value[key] * 2,
    },
    [`${key}Triple`]: {
      [key]: value[key] * 3,
    },
    [`${key}Quadra`]: {
      [key]: value[key] * 4,
    },
    [`${key}Penta`]: {
      [key]: value[key] * 5,
    },
    [`${key}Hex`]: {
      [key]: value[key] * 6,
    },
    [`${key}Hepta`]: {
      [key]: value[key] * 7,
    },
  }),
  {},
);

const globalStyleNoMargins = {
  noMargin: {
    marginVertical: 0,
    marginHorizontal: 0,
  },
  noPadding: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
};

const globalStyleAutoMargins = {
  marginTopAuto: {
    marginTop: 'auto',
  },
  marginRightAuto: {
    marginRight: 'auto',
  },
  marginBottomAuto: {
    marginBottom: 'auto',
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
};

export default {
  ...globalStyleMargins,
  ...globalStyleMarginsNegative,
  ...globalStyleNoMargins,
  ...globalStyleAutoMargins,
};
