import constants from './constants';

const globalStyleGeneral = {
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  relative: {
    position: 'relative',
  },
  absolute: {
    position: 'absolute',
  },
  fixed: {
    position: 'fixed',
  },
  absoluteTop: {
    position: 'absolute',
    top: 0,
  },
  absoluteBottom: {
    position: 'absolute',
    bottom: 0,
  },
  absoluteRight: {
    position: 'absolute',
    right: 0,
  },
  imageFull: {
    width: '100%',
    aspectRatio: 1,
    resizeMode: 'contain',
  },
  heightFull: {
    height: '100%',
  },
  heightViewportFull: {
    height: constants.heightViewportFull,
  },
  widthFull: {
    width: '100%',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorDefault: {
    cursor: 'default',
  },
};

export default globalStyleGeneral;
