import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { HomeFeedModel, NotificationsModel, NotificationData } from '../models/Feed.model';

export const feedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHomeFeed: builder.mutation<HomeFeedModel, any>({
      query: () => ({
        url: Endpoints.getHomeFeed,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getNotificationList: builder.mutation<NotificationsModel, any>({
      query: (body) => ({
        url: Endpoints.notification,
        method: 'GET',
        params: body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    updateNotification: builder.mutation<NotificationData, any>({
      query: ({ status, nid }) => ({
        url: `${Endpoints.notification}/${status}/${nid}`,
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetHomeFeedMutation,
  useGetNotificationListMutation,
  useUpdateNotificationMutation,
} = feedApi;
