import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { WalletEventModel } from '../../../models/Wallet.model';

interface UserPointsState {
  total_points_consumed: number | null;
  total_codes_entered: number | null;
  staticPages: any;
}
const getInitialState = (): UserPointsState => ({
  total_points_consumed: null,
  total_codes_entered: null,
  staticPages: null,
});

const slice = createSlice({
  name: 'userPoints',
  initialState: getInitialState(),
  reducers: {
    addStaticPages: (state, action) => {
      state.staticPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getUserPoints.matchFulfilled, (state, { payload }) => {
      state.total_points_consumed = payload.total_points_consumed;
      if (payload.data) {
        state.total_codes_entered = Object.values(payload.data)
          .flat()
          .filter((item) => (item as WalletEventModel)?.subtype?.startsWith('pack_codes')).length;
      }
    });
    builder.addMatcher(api.endpoints.fetchStaticFilePages.matchFulfilled, (state, { payload }) => {
      state.staticPages = payload;
    });
  },
});

export const { addStaticPages } = slice.actions;
export default slice.reducer;
