import constants from './constants';

const globalStyleZIndex = {
  zIndex: {
    zIndex: constants.gridGutterWidth,
  },
  zIndexDouble: {
    zIndex: constants.gridGutterWidth * 2,
  },
  zIndexTriple: {
    zIndex: constants.gridGutterWidth * 3,
  },
  zIndexQuadra: {
    zIndex: constants.gridGutterWidth * 4,
  },
  zIndexPenta: {
    zIndex: constants.gridGutterWidth * 5,
  },
};

export default globalStyleZIndex;
