import React, { ComponentType, lazy, memo, Suspense, useMemo } from 'react';
import { BATLazyError, BATLazyLoading } from './BATLazy';
import { Factory, screenFactory } from './screenFactory';

export const anonymousLazyScreen = <P, T extends ComponentType<P> = ComponentType<any>>(
  factory: Factory<T>,
  ErrorComponent: React.ComponentType = BATLazyError,
  LoadingComponent: React.ComponentType = BATLazyLoading,
): React.ComponentType<P> => {
  const Component = lazy(() => screenFactory(factory, ErrorComponent));

  return memo(function AnonymousLazyScreen(props) {
    const fallback = useMemo(() => <LoadingComponent />, []);

    return (
      <Suspense fallback={fallback}>
        {/* @ts-expect-error */}
        <Component {...props} />
      </Suspense>
    );
  });
};
