import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { CardProps } from '../../../src/components/BATCard';
import { orderBrands } from '../../../utils/brandUtils';

interface CollectionProps extends CardProps {
  hasReadAllArticles: boolean;
  unreadArticles: number;
}

const getInitialState = () => ({
  brands: [] as string[],
  collections: {} as Record<string, CollectionProps[]>,
});

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: getInitialState(),
  reducers: {
    setCollections: (state, action) => {
      const initialData = action.payload;
      state.brands = [];
      const collectionsByBrand = Object.keys(initialData);
      state.brands = orderBrands(collectionsByBrand);
      state.brands.forEach((brand) => {
        state.collections[brand] = [];
        const collections = initialData[brand];
        if (collections) {
          const collectionNames = Object.keys(collections);
          collectionNames.forEach((name) => {
            state.collections[brand].push({
              territory_url_alias:
                collections[name].territory_url_alias ?? collections[name].author_url_alias,
              image: collections[name].cover,
              url_alias:
                collections[name].brand_url_alias && collections[name].territory_url_alias
                  ? `/articole${collections[name].brand_url_alias}${collections[name].territory_url_alias}`
                  : `/articole/${brand.toLowerCase().replaceAll(' ', '-')}${
                      collections[name].author_url_alias
                    }`,
              brand: brand,
              badge:
                Number(collections[name].articles_not_read_number) > 0
                  ? {
                      variant: 'article',
                      label: `${
                        Number(collections[name].articles_not_read_number) === 1
                          ? `1 Articol necitit`
                          : `${Number(
                              collections[name].articles_not_read_number,
                            )} Articole necitite`
                      }`,
                      icon: {
                        name: 'article-badge',
                        size: 16,
                      },
                    }
                  : undefined,
              hasReadAllArticles: !(collections[name].articles_not_read_number > 0),
              unreadArticles: collections[name].articles_not_read_number,
            });
          });
        }
      });
    },
    decreaseUnreadArticles: (state, action) => {
      const brand = action.payload.brand;
      const territorySlug = action.payload.territorySlug;
      if (state.collections[brand] && territorySlug) {
        const existingCollection = state.collections[brand].find((collection) => {
          return collection.territory_url_alias === territorySlug;
        });
        if (existingCollection) {
          existingCollection.unreadArticles = existingCollection.unreadArticles - 1;
          switch (true) {
            case existingCollection.unreadArticles <= 0:
              existingCollection.badge = undefined;
              existingCollection.hasReadAllArticles = true;
              break;
            case existingCollection.unreadArticles === 1:
              existingCollection.badge = {
                variant: 'article',
                label: `1 Articol necitit`,
                icon: {
                  name: 'article-badge',
                  size: 16,
                },
              };
              break;
            default:
              existingCollection.badge = {
                variant: 'article',
                label: `${existingCollection.unreadArticles} Articole necitite`,
                icon: {
                  name: 'article-badge',
                  size: 16,
                },
              };
              break;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const { setCollections, decreaseUnreadArticles } = collectionsSlice.actions;
export default collectionsSlice.reducer;
