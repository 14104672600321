import moment from 'moment';
import { BundleDeliveryMethods } from '../../../models/Prize.model';
import { isNullOrEmptyString } from '../../../utils/stringUtils';

export const isInStock = (stock: string | number | null | undefined): boolean => {
  if (isNullOrEmptyString(stock as string)) return true;
  return stock !== '0' && stock !== 0;
};

export const sum = (a: number | string, b: number | string) => {
  return Number(a) + Number(b);
};

export const minFieldPoints = (
  data: any,
): {
  field_activity_points: number;
  field_pack_points: number;
  field_delivery_method?: string;
  displayPoints?: string;
  final_activity_points?: number;
  final_loyalty_points?: number;
  discounted_end_date?: string;
} => {
  const {
    field_activity_points,
    field_pickup_extra_activity,
    field_shipping_extra_activity,
    field_pack_points,
    field_pickup_extra_loyalty,
    field_shipping_extra_loyalty,
    field_delivery_method,
  } = data;

  if (!field_delivery_method) {
    return {
      field_activity_points,
      field_pack_points,
      field_delivery_method,
    };
  }

  const shippingActivityPointsSum = sum(field_activity_points, field_shipping_extra_activity);
  const shippingLoyaltyPointsSum = sum(field_pack_points, field_shipping_extra_loyalty);

  const pickupActivityPointsSum = sum(field_activity_points, field_pickup_extra_activity);
  const pickupLoyaltyPointsSum = sum(field_pack_points, field_pickup_extra_loyalty);

  switch (field_delivery_method) {
    case BundleDeliveryMethods.Shipping:
      return {
        field_activity_points: shippingActivityPointsSum,
        field_pack_points: shippingLoyaltyPointsSum,
        field_delivery_method,
        ...minDiscountPoints(data),
      };
    case BundleDeliveryMethods.Pickup:
      return {
        field_activity_points: pickupActivityPointsSum,
        field_pack_points: pickupLoyaltyPointsSum,
        field_delivery_method,
        ...minDiscountPoints(data),
      };
    default:
      if (
        shippingActivityPointsSum + shippingLoyaltyPointsSum ===
        pickupActivityPointsSum + pickupLoyaltyPointsSum
      ) {
        return {
          field_activity_points: pickupActivityPointsSum,
          field_pack_points: pickupLoyaltyPointsSum,
          ...minDiscountPoints(data),
        };
      }

      if (
        shippingActivityPointsSum + shippingLoyaltyPointsSum >
        pickupActivityPointsSum + pickupLoyaltyPointsSum
      ) {
        return {
          field_activity_points: pickupActivityPointsSum,
          field_pack_points: pickupLoyaltyPointsSum,
          field_delivery_method: BundleDeliveryMethods.Pickup,
          displayPoints: field_delivery_method === BundleDeliveryMethods.Any ? 'from' : undefined,
          ...minDiscountPoints(data),
        };
      } else {
        return {
          field_activity_points: shippingActivityPointsSum,
          field_pack_points: shippingLoyaltyPointsSum,
          field_delivery_method: BundleDeliveryMethods.Shipping,
          displayPoints: field_delivery_method === BundleDeliveryMethods.Any ? 'from' : undefined,
          ...minDiscountPoints(data),
        };
      }
  }
};

const minDiscountPoints = (data: any) => {
  const {
    discounted_start_date_pickup,
    discounted_end_date_pickup,
    discounted_end_date_shipping,
    discounted_start_date_shipping,
    final_activity_points_pickup,
    final_loyalty_points_pickup,
    final_activity_points_shipping,
    final_loyalty_points_shipping,
    set_discounted_price,
  } = data;

  const pickupFieldsValid = [
    discounted_start_date_pickup,
    discounted_end_date_pickup,
    final_activity_points_pickup,
    final_loyalty_points_pickup,
  ].every(Boolean);

  const shippingFieldsValid = [
    discounted_start_date_shipping,
    discounted_end_date_shipping,
    final_activity_points_shipping,
    final_loyalty_points_shipping,
  ].every(Boolean);

  const now = moment(moment(), 'DD/MM/YYYY');
  const isPickupDiscountExpired = !(
    pickupFieldsValid &&
    !now.isAfter(moment(discounted_end_date_pickup, 'DD/MM/YYYY').add(1, 'days')) &&
    !now.isBefore(moment(discounted_start_date_pickup, 'DD/MM/YYYY')) &&
    Number(set_discounted_price) !== 0
  );

  const isShippingDiscountExpired = !(
    shippingFieldsValid &&
    !now.isAfter(moment(discounted_end_date_shipping, 'DD/MM/YYYY').add(1, 'days')) &&
    !now.isBefore(moment(discounted_start_date_shipping, 'DD/MM/YYYY')) &&
    Number(set_discounted_price) !== 0
  );

  if (!isPickupDiscountExpired && !isShippingDiscountExpired) {
    const startDatePickup = moment(discounted_start_date_pickup, 'DD/MM/YYYY');
    const endDatePickup = moment(discounted_end_date_pickup, 'DD/MM/YYYY');
    const startDateShipping = moment(discounted_start_date_shipping, 'DD/MM/YYYY');
    const endDateShipping = moment(discounted_end_date_shipping, 'DD/MM/YYYY');
    const currentDate = moment();

    const datesEqual =
      startDatePickup.isSame(startDateShipping) && endDatePickup.isSame(endDateShipping);

    const datesOverlap =
      startDatePickup.isSameOrBefore(endDateShipping) &&
      endDatePickup.isSameOrAfter(startDateShipping);

    const validStartDates =
      startDatePickup.isSameOrBefore(currentDate) && startDateShipping.isSameOrBefore(currentDate);

    if (validStartDates && (datesEqual || datesOverlap)) {
      const pickupSum = sum(final_activity_points_pickup, final_loyalty_points_pickup);
      const shippingSum = sum(final_activity_points_shipping, final_loyalty_points_shipping);

      if (pickupSum === shippingSum) {
        return {
          final_activity_points: final_activity_points_pickup,
          final_loyalty_points: final_loyalty_points_pickup,
          discounted_end_date: discounted_end_date_pickup,
          discounted_start_date: discounted_start_date_pickup,
        };
      } else if (pickupSum < shippingSum) {
        return {
          final_activity_points: final_activity_points_pickup,
          final_loyalty_points: final_loyalty_points_pickup,
          discounted_end_date: discounted_end_date_pickup,
          discounted_start_date: discounted_start_date_pickup,
          displayPoints: 'from',
        };
      } else {
        return {
          final_activity_points: final_activity_points_shipping,
          final_loyalty_points: final_loyalty_points_shipping,
          discounted_end_date: discounted_end_date_shipping,
          discounted_start_date: discounted_start_date_shipping,
          displayPoints: 'from',
        };
      }
    }
  }

  if (!isPickupDiscountExpired) {
    return {
      final_activity_points: final_activity_points_pickup,
      final_loyalty_points: final_loyalty_points_pickup,
      discounted_end_date: discounted_end_date_pickup,
      discounted_start_date: discounted_start_date_pickup,
    };
  }

  if (!isShippingDiscountExpired) {
    return {
      final_activity_points: final_activity_points_shipping,
      final_loyalty_points: final_loyalty_points_shipping,
      discounted_end_date: discounted_end_date_shipping,
      discounted_start_date: discounted_start_date_shipping,
    };
  }

  return {};
};
