import { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';

import { RootState } from '../../store';
import { NotificationData } from '../../../models/Feed.model';

interface BATAnnouncementToastProps {
  notifications: NotificationData[];
  handleSeenNotification: (id: string) => void;
}

const BATAnnouncementToast = ({
  notifications,
  handleSeenNotification,
}: BATAnnouncementToastProps) => {
  const [queue, setQueue] = useState<NotificationData[]>([]);

  useEffect(() => {
    setQueue(notifications);
  }, [notifications]);

  const isLoggedIn = useSelector((state: RootState) => state.auth.userData) !== null;

  const handleOnClose = () => {
    handleSeenNotification(queue[0].nid);
    const newQueue = [...queue];
    newQueue.shift();
    setQueue(newQueue);
    Toast.hide();
  };

  const handleOnPress = () => {
    handleSeenNotification(queue[0].nid);
    Linking.openURL(queue[0].template.cta_link_external);
  };

  const formatText = (text: string) => {
    return text.replaceAll(/<[^>]*>/g, '');
  };

  useEffect(() => {
    if (isLoggedIn && queue.length) {
      setTimeout(() => {
        Toast.show({
          type: 'announce',
          autoHide: false,
          text1: formatText(queue[0].template.title),
          text2: formatText(queue[0].template.body),
          props: {
            onClose: handleOnClose,
          },
          onPress: handleOnPress,
        });
      }, 400);
    } else {
      Toast.hide();
    }
  }, [queue, isLoggedIn]);

  return null;
};
export default BATAnnouncementToast;
