import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import {
  CustomQuizzTaxonomyItem,
  GetCustomQuizzesResponse,
  SendCustomQuizzResponse,
} from '../models/CustomQuizz.model';

export const customQuizz = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomQuizzTaxonomyItems: builder.mutation<CustomQuizzTaxonomyItem[], string>({
      query: (name) => ({
        url: Endpoints.getCustomQuizzTaxonomyItems.replace('{{name}}', name),
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getCustomQuizzes: builder.mutation<GetCustomQuizzesResponse, void>({
      query: () => ({
        url: Endpoints.customQuizz,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    sendCustomQuizz: builder.mutation<SendCustomQuizzResponse, Record<string, string>>({
      query: (userResponse) => ({
        url: Endpoints.customQuizz,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: userResponse,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCustomQuizzTaxonomyItemsMutation,
  useGetCustomQuizzesMutation,
  useSendCustomQuizzMutation,
} = customQuizz;
