import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { WebformModel } from '../models/Survey.model';

export const surveyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveys: builder.mutation<any, any>({
      query: (all) => ({
        url: `${Endpoints.getSurveysCard}/${all}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getSurvey: builder.mutation<WebformModel, { id: string }>({
      query: ({ id }) => ({
        url: Endpoints.getWebform.replace('{{id}}', id),
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    postSurvey: builder.mutation<any, any>({
      query: ({ answers }) => ({
        url: Endpoints.postWebformSubmit,
        method: 'POST',
        body: answers,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSurveysMutation, usePostSurveyMutation, useGetSurveyMutation } = surveyApi;
