import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { API_URL } from 'react-native-dotenv';
import i18n from '../../i18n';
import { logoutUser, refreshToken } from '../../screens/Authentication/slices/extraSlice';
import { RootState } from '../../src/store';
import Endpoints from './endpoints';
import Toast from 'react-native-toast-message';

function isErrorStatus(status: any) {
  return status === 500 || status === 503 || status === 'FETCH_ERROR';
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    const consumerId = (getState() as RootState).auth.consumerId;

    if (token) {
      headers.set('Authorization', 'Bearer ' + token);
    }

    if (consumerId) {
      headers.set('consumerId', consumerId);
    }

    return headers;
  },
});

const baseQueryWithoutToken = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const consumerId = (getState() as RootState).auth.consumerId;

    if (consumerId) {
      headers.set('consumerId', consumerId);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.originalStatus === 401 || result.error.status === 401) &&
    !result.meta?.request.url.includes(Endpoints.login)
  ) {
    // try to get a new token
    const refreshResult = await baseQueryWithoutToken(
      {
        url: Endpoints.login,
        method: 'POST',
        body: `refresh_token=${encodeURIComponent(
          (api.getState() as RootState).auth.refreshToken ?? '',
        )}&grant_type=custom_refresh`,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      // store the new token
      api.dispatch(refreshToken(refreshResult.data));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else if (!refreshResult.data) {
      api.dispatch(logoutUser());
    }
  }
  if (isErrorStatus(result.error?.originalStatus) || isErrorStatus(result.error?.status)) {
    const message = i18n.t('503_ERROR');
    Toast.show({
      type: 'error',
      text2: message,
    });
  }
  return result;
};

const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export default api;
