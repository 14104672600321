import { useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { Button } from 'react-native-paper';

import { sharedStyles } from '../../../config/theme/shared.styles';
import colors from '../../../config/theme/shared/colors';

export type ButtonSize = 'small' | 'base' | 'large';

interface BATButtonNewProps {
  variant: 'contained' | 'outlined' | 'text';
  size?: ButtonSize;
  disabled?: boolean;
  looksAsDisabled?: boolean;
  customStyle?: any;
  handleSubmitButton: () => void;
  children: any;
  customContentStyle?: ViewStyle;
}

const BATButtonNew = ({
  variant,
  size = 'base',
  disabled,
  looksAsDisabled,
  customStyle,
  handleSubmitButton,
  children,
  customContentStyle,
}: BATButtonNewProps) => {
  const [isPressed, setIsPressed] = useState(false);

  const handlePressIn = () => {
    setIsPressed(true);
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };
  return (
    <Button
      mode={variant}
      disabled={disabled}
      style={[
        sharedStyles.justifyContentCenter,
        sharedStyles.borderRadiusHalf,
        isPressed ? styleByVariantOnPressed[variant] : styleByVariant[variant],
        (disabled || looksAsDisabled) && disabledContainerStyleByVariant[variant],
        customStyle,
      ]}
      labelStyle={[
        styles.rubik500Medium,
        styles.customStyle,
        labelStyleByVariant[variant],
        labelStyleBySize[size],
        (disabled || looksAsDisabled) && styles.labelButtonDisabled,
      ]}
      contentStyle={customContentStyle ?? contentStyleBySize[size]}
      uppercase={false}
      onPress={handleSubmitButton}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}>
      {children}
    </Button>
  );
};

const styles = StyleSheet.create({
  customStyle: {
    whiteSpace: 'normal',
    letterSpacing: 0,
  },
  rubik500Medium: {
    fontFamily: 'Rubik_500Medium',
    fontWeight: '500',
  },
  labelButtonDisabled: {
    color: colors.backgroundMedium,
  },
});

const styleByVariant = StyleSheet.create({
  contained: {
    backgroundColor: colors.dark_accent,
    borderWidth: 0,
  },
  outlined: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.violet,
  },
  text: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
});

const styleByVariantOnPressed = StyleSheet.create({
  contained: {
    backgroundColor: colors.dark_accent,
    borderWidth: 0,
  },
  outlined: {
    backgroundColor: colors.active,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.violet,
  },
  text: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
});

const labelStyleByVariant = StyleSheet.create({
  contained: {
    color: colors.grey_title,
  },
  outlined: {
    color: colors.violet,
  },
  text: {
    color: colors.violet,
    textDecorationLine: 'underline',
  },
});

const labelStyleBySize = StyleSheet.create({
  small: {
    fontSize: 14,
    lineHeight: 16,
  },
  base: {
    fontSize: 16,
    lineHeight: 20,
  },
  large: {
    fontSize: 18,
    lineHeight: 24,
  },
});

const contentStyleBySize = StyleSheet.create({
  small: {
    minHeight: 32,
  },
  base: {
    minHeight: 48,
  },
  large: {
    minHeight: 56,
  },
});

const disabledContainerStyleByVariant = StyleSheet.create({
  contained: {
    backgroundColor: colors.card_background_lighter,
  },
  outlined: {
    borderColor: colors.blueDark,
  },
  text: {
    backgroundColor: colors.card_background_lighter,
  },
});

export default BATButtonNew;
