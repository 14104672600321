import api from '../../../config/api';

import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../Authentication/slices/extraSlice';

const getInitialState = () => ({
  unread: 0,
});

const slice = createSlice({
  name: 'notification',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getNotificationList.matchFulfilled, (state, { payload }) => {
      state.unread = payload.notifications.total.unread;
    });
  },
});

export default slice.reducer;
