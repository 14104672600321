import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { brandDetails } from '../../../utils/brandUtils';
import { EVENT_CATEGORY_FE } from '../utils';
import { WalletEventModel } from '../../../models/Wallet.model';

export enum POINTS_NAME {
  ACTIVITY = 'activity',
  LOYALTY = 'loyalty',
}

export interface ExpireState {
  alert?: 'danger' | 'warning';
}

interface PointsDetails extends ExpireState {
  total: number;
  toExpire: number;
}

interface BrandPoints extends ExpireState {
  brandName: string;
  points: number | string;
  pointsToExpire: number;
}

export type HistoryEvents = {
  event_type: EVENT_CATEGORY_FE;
  event_title: string;
  event_description: string;
  event_date: string;
  loyalty_points?: number;
  activity_points?: number;
};

export interface HistoryData {
  date: string;
  events: HistoryEvents[];
}

const formatAlertState = (
  totalPoints: number,
  totalPointsToExpire: number,
  expirationDate: Date,
) => {
  if (!totalPoints || !totalPointsToExpire) {
    return undefined;
  }

  const currentDate = new Date();
  const timeDifferences = expirationDate.getTime() - currentDate.getTime();
  const differenceInDays = Math.ceil(timeDifferences / (1000 * 3600 * 24));

  if (differenceInDays < 15) {
    return 'danger';
  } else if (differenceInDays < 30) {
    return 'warning';
  }
  return undefined;
};

const getInitialState = () => ({
  expirationDate: '' as string,
  pointsDetails: {} as Record<string, PointsDetails>,
  brandPoints: [] as BrandPoints[],
  historyData: [] as HistoryData[],
});

const walletSlice = createSlice({
  name: 'wallet',
  initialState: getInitialState(),
  reducers: {
    setWalletPoints: (state, action) => {
      const { detailed_totals: data } = action.payload;
      const formattedExpirationDate = moment(data.activity.date, 'DD/MM/YYYY').format('YYYY-MM-DD');

      state.expirationDate = formattedExpirationDate;
      state.pointsDetails[POINTS_NAME.ACTIVITY] = {
        total: data.activity.total,
        toExpire: data.activity.total_to_expire,
        alert: formatAlertState(
          data.activity.total,
          data.activity.total_to_expire,
          new Date(formattedExpirationDate),
        ),
      };

      state.pointsDetails[POINTS_NAME.LOYALTY] = {
        total: data.loyalty.total,
        toExpire: data.loyalty.total_to_expire,
        alert: formatAlertState(
          data.loyalty.total,
          data.loyalty.total_to_expire,
          new Date(formattedExpirationDate),
        ),
      };

      state.brandPoints = [];
      state.brandPoints = brandDetails
        .filter((brand) => !brand.hideFromList)
        .map((brand) => {
          const brandData = data.loyalty[brand.name.toLowerCase().replaceAll(' ', '_')];
          const { total, to_expire } = brandData || { total: 0, to_expire: 0 };

          return {
            brandName: brand.name,
            points: `${total}`,
            pointsToExpire: to_expire,
            alert: formatAlertState(total, to_expire, new Date(formattedExpirationDate)),
          };
        });
    },
    setHistoryPointsData: (state, action) => {
      const { data: groupedData } = action.payload;

      state.historyData = Object.entries(groupedData).map(([date, events]) => ({
        date: moment(date, 'YYYY-MM').format('MM/YYYY'),
        events: (events as WalletEventModel[]).map((item: WalletEventModel) => ({
          event_type: item.subtype,
          event_title: item.event_category,
          event_description: item.event_name,
          event_date: item.earned_date,
          loyalty_points: item.type === POINTS_NAME.LOYALTY ? +item.amount : undefined,
          activity_points: item.type === POINTS_NAME.ACTIVITY ? +item.amount : undefined,
        })) as HistoryEvents[],
      }));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
  },
});

export const { setWalletPoints, setHistoryPointsData } = walletSlice.actions;
export default walletSlice.reducer;
