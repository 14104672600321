import { DefaultTheme, MD2DarkTheme, configureFonts } from 'react-native-paper';
import {
  DefaultTheme as NavDefaultTheme,
  DarkTheme as NavDarkTheme,
} from '@react-navigation/native';
import colors from './shared/colors';

const _fontConfig = {
  thin: {
    fontFamily: 'Rubik_300Light',
    fontWeight: 'normal',
  },
  light: {
    fontFamily: 'Rubik_300Light',
    fontWeight: 'normal',
  },
  regular: {
    fontFamily: 'Rubik_400Regular',
    fontWeight: 'normal',
  },
  medium: {
    fontFamily: 'Rubik_500Medium',
    fontWeight: 'normal',
  },
  semiBold: {
    fontFamily: 'Rubik_600SemiBold',
    fontWeight: 'normal',
  },
  bold: {
    fontFamily: 'Rubik_700Bold',
    fontWeight: 'normal',
  },
};

const fontConfig = {
  web: _fontConfig,
  android: _fontConfig,
  ios: _fontConfig,
};

// const theme = {
//   ...DefaultTheme,
//   ...NavDefaultTheme,
//   roundness: 5,
//   colors: {
//     ...DefaultTheme.colors,
//     ...NavDefaultTheme.colors,
//     primary,
//   },
// };

const darkTheme = {
  ...MD2DarkTheme,
  ...NavDarkTheme,
  roundness: 5,
  fonts: configureFonts({ config: fontConfig, isV3: false }),
  colors: {
    ...MD2DarkTheme.colors,
    ...NavDarkTheme.colors,
    ...colors,
  },
};

// export { theme, darkTheme };
export { darkTheme };
