import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { AddressModel, CityModel, ContactUsModel } from '../models/Profile';
import { WalletModel } from '../models/Wallet.model';

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<any, any>({
      query: ({ password, currentPassword }) => ({
        url: Endpoints.register,
        method: 'PATCH',
        body: { password, currentPassword },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getAddress: builder.mutation<AddressModel[], any>({
      query: () => ({
        url: Endpoints.getAddress,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    fetchInviteFriendLink: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.inviteFriend,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getCity: builder.mutation<CityModel[], any>({
      query: ({ tid }) => ({
        url: `${Endpoints.getAddress}/${tid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
      transformResponse: (citiesWithDuplicates: CityModel[]) => {
        const uniqueCities = citiesWithDuplicates.filter(
          (city, index) =>
            citiesWithDuplicates.findIndex(({ name }) => name === city.name) === index,
        );
        return uniqueCities;
      },
    }),
    getUserPoints: builder.mutation<WalletModel, any>({
      query: ({ history }) => ({
        url: `${Endpoints.getUserPoints}`,
        method: 'GET',
        params: { history: history ?? 0 },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    postContactUs: builder.mutation<ContactUsModel, any>({
      query: (body) => ({
        url: Endpoints.contactUs,
        method: 'POST',
        body,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchInviteFriendLinkMutation,
  useChangePasswordMutation,
  useGetAddressMutation,
  useGetCityMutation,
  useGetUserPointsMutation,
  usePostContactUsMutation,
} = profileApi;
