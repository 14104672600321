import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';
import { ScreenNames } from '../ScreenNames';

const ArticleView = protectedLazyScreen(() => import('./ArticleView'));
const Articles = protectedLazyScreen(() => import('./Articles'));
const ProductArticles = protectedLazyScreen(() => import('./ProductArticles'));
const PallMallArticle = protectedLazyScreen(() => import('./PallMallArticle'));

const Stack = createStackNavigator();

const ArticleFlow = () => {
  return (
    <Stack.Navigator initialRouteName={ScreenNames.articleList}>
      <Stack.Screen
        name={ScreenNames.articleView}
        options={{ title: 'Article View', headerShown: false }}
        component={ArticleView}
      />
      <Stack.Screen
        name={ScreenNames.articleList}
        options={{ title: 'Article List', headerShown: false }}
        component={Articles}
      />
      <Stack.Screen
        name={ScreenNames.productArticleList}
        options={{ title: 'Article List', headerShown: false }}
        component={ProductArticles}
      />
      <Stack.Screen
        name={ScreenNames.pallMallArticle}
        options={{ title: 'Article View', headerShown: false }}
        component={PallMallArticle}
      />
    </Stack.Navigator>
  );
};

export default ArticleFlow;
