import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { PackCodeConsumeSuccess } from '../models/PackCode.model';

export const packCodesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    packCodeConsume: builder.mutation<PackCodeConsumeSuccess, any>({
      query: ({ packCode, captchaData, params }) => ({
        url: `${Endpoints.consumePackCode}/${packCode}`,
        method: 'POST',
        body: captchaData,
        params,
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { usePackCodeConsumeMutation } = packCodesApi;
