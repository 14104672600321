import React, { FC, memo } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { useTheme } from 'react-native-paper';

export const BATLazyLoading = memo(function BATLazyLoading(props) {
  const { colors } = useTheme();
  return (
    <View
      style={[{ height: '100%', justifyContent: 'center', backgroundColor: colors.background }]}>
      <ActivityIndicator animating={true} color={colors.text} size={'large'} />
    </View>
  );
});

type BATLazyErrorProps = {
  message?: string;
};

export const BATLazyError: FC<BATLazyErrorProps> = (props) => {
  const { colors } = useTheme();

  return (
    <View style={[{ height: '100%', justifyContent: 'center' }]}>
      <Text style={[{ color: colors.text, textAlign: 'center' }]}>{props.message}</Text>
    </View>
  );
};

BATLazyError.defaultProps = {
  message: 'An error occurred!',
};

// export const createBATLazyError = (message?: string) => {
//     return memo(() => <BATLazyError message={message} />);
// };
