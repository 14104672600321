import {
  LinkingOptions,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { memo, useMemo, useRef } from 'react';
import { darkTheme } from '../config/theme';
import { ScreenNames } from '../screens/ScreenNames';
import AgeVerificationFlow from './AgeVerificationFlow';

export default memo(function AgeVerificationContainer() {
  const stackRef = useRef(createStackNavigator());
  const Stack = stackRef.current;
  const navigationContainerRef = useNavigationContainerRef();

  const linking: LinkingOptions<{}> = useMemo(
    () => ({
      enabled: true,
      prefixes: [],
    }),
    [],
  );

  return (
    <NavigationContainer
      ref={navigationContainerRef}
      linking={linking}
      theme={darkTheme}
      independent={true}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name={ScreenNames.ageVerificationStack} component={AgeVerificationFlow} />
      </Stack.Navigator>
    </NavigationContainer>
  );
});
