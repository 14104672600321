import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { GetItemsBasicFilters } from '../models/common';
import { GetCampaignGalleryFilters, GetPromotedCampaignsResponse } from '../models/Campaigns.model';

export const campaignsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignsList: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.getCampaignsList,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getCampaignsActivitiesList: builder.mutation<any, any>({
      query: () => ({
        url: Endpoints.getCampaignsActivitiesList,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getCampaignDetails: builder.mutation<any, any>({
      query: (campaignSlug) => ({
        url: `${Endpoints.getCampaignDetails}/${campaignSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getCampaignGallery: builder.mutation<any, any>({
      query: ({
        campaignSlug,
        filters,
      }: {
        campaignSlug: string;
        filters: GetCampaignGalleryFilters;
      }) => ({
        url: `${Endpoints.getCampaignGallery}/${campaignSlug}/gallery?_format=json`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        params: filters,
      }),
    }),
    getCampaignMyGallery: builder.mutation<any, any>({
      query: ({
        campaignSlug,
        filters,
      }: {
        campaignSlug: string;
        filters: GetItemsBasicFilters;
      }) => ({
        url: `${Endpoints.getCampaignGallery}/${campaignSlug}/user_gallery?_format=json`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
        params: filters,
      }),
    }),
    postCampaignUploadFile: builder.mutation<any, any>({
      query: ({ campaignSlug, binaryFile }) => ({
        url: `${Endpoints.getCampaignGallery}/${campaignSlug}/upload?_format=json`,
        method: 'POST',
        headers: {
          'Content-type': 'application/octet-stream',
          'Content-disposition': 'attachment; filename="filename.jpg"',
        },
        body: binaryFile,
      }),
    }),
    postCampaignUploadForm: builder.mutation<any, any>({
      query: ({ campaignSlug, campaignForm }) => ({
        url: `${Endpoints.getCampaignGallery}/${campaignSlug}/submit?_format=json`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: campaignForm,
      }),
    }),
    flagCampaign: builder.mutation<any, any>({
      query: ({ sid }) => ({
        url: Endpoints.flagCampaign,
        method: 'POST',
        body: {
          sid,
        },
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getPromotedCampaigns: builder.mutation<GetPromotedCampaignsResponse, {}>({
      query: () => ({
        url: '/api/promoted-campaigns',
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCampaignsListMutation,
  useGetCampaignsActivitiesListMutation,
  useGetCampaignDetailsMutation,
  useGetCampaignGalleryMutation,
  useGetCampaignMyGalleryMutation,
  usePostCampaignUploadFormMutation,
  usePostCampaignUploadFileMutation,
  useFlagCampaignMutation,
  useGetPromotedCampaignsMutation,
} = campaignsApi;
