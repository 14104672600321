import React, { useEffect, useState } from 'react';
import { View, Text, SafeAreaView, Linking } from 'react-native';
import { useTheme } from 'react-native-paper';

import { NotificationData } from '../../../models/Feed.model';

import BATIcon from '../BATIcon';
import { sharedStyles } from '../../../config/theme/shared.styles';

interface BATAnnouncementStickyProps {
  notifications: NotificationData[];
  handleSeenNotification: (id: string) => void;
}

const BATAnnouncementSticky = ({
  notifications,
  handleSeenNotification,
}: BATAnnouncementStickyProps) => {
  const [queue, setQueue] = useState<NotificationData[]>([]);

  const { colors } = useTheme();

  useEffect(() => {
    setQueue(notifications);
  }, [notifications]);

  const handleOnClose = () => {
    handleSeenNotification(queue[0].nid);
    const newQueue = [...queue];
    newQueue.shift();
    setQueue(newQueue);
  };

  const handleOnPress = () => {
    Linking.openURL(queue[0].template.cta_link_external);
    handleOnClose();
  };

  if (!queue.length) {
    return null;
  }

  return (
    <SafeAreaView
      style={[
        sharedStyles.absolute,
        sharedStyles.alignItemsCenter,
        sharedStyles.widthFull,
        sharedStyles.zIndex,
      ]}>
      <View
        style={[
          sharedStyles.widthFull,
          sharedStyles.padding,
          sharedStyles.alignItemsCenter,
          sharedStyles.justifyContentCenter,
          sharedStyles.displayFlex,
          {
            flexWrap: 'nowrap',
            gap: 16,
            backgroundColor: colors.white_opacity_95,
          },
        ]}>
        <Text style={{ fontSize: 14, color: colors.darkText, textAlign: 'center', flex: 1 }}>
          {queue[0].template.title}{' '}
          <Text style={[{ fontWeight: 'bold', color: colors.primary }]} onPress={handleOnPress}>
            {queue[0].template.cta_text_teaser}
          </Text>
        </Text>
        <BATIcon
          name="close"
          color="white"
          style={[{ backgroundColor: colors.cardsBackground, padding: 5, borderRadius: 50 }]}
          onPress={handleOnClose}
        />
      </View>
    </SafeAreaView>
  );
};
export default BATAnnouncementSticky;
