import { createSlice } from '@reduxjs/toolkit';

import { CustomQuizzTaxonomyItem } from '../../../models/CustomQuizz.model';
import {
  TasteProfileMainOption,
  TasteProfileOption,
  TasteProfileState,
  TasteProfileToolTipQuestion,
} from '../../../models/TasteProfile.model';

const getFrequencyOptions = (secondaryQuestions: Record<number, TasteProfileToolTipQuestion>) => {
  const options = [
    'Cel puțin o dată pe zi',
    'De câteva ori pe săptămână, dar nu zilnic',
    'De câteva ori pe lună, dar nu săptămânal',
    'Mai rar de o dată pe lună',
    'Nu folosesc acest tip de produs',
  ].map((text) => ({ text, preselected: false, selected: false }) as TasteProfileOption);

  Object.entries(secondaryQuestions).forEach(([index, secondaryQuestion]) => {
    options[+index].secondaryQuestion = secondaryQuestion;
  });

  return options;
};

const getQuantityOptions = (max: number) => {
  return [...new Array(max)].map((_, index) => ({ text: `${index + 1}`, selected: false }));
};

const getInitialState = (): TasteProfileState => ({
  availableDateDMY: '',
  points: '',
  period: '',
  mainQuestion: {
    code: 'general_1',
    text: 'Cât de des utilizezi fiecare dintre aceste produse?',
    mention: 'Selectează răspunsul corespunzător fiecărui tip de produs',
    options: [
      {
        termCode: 'tigarete_clasice',
        text: 'Țigarete clasice',
        selectionText: '',
        imageUrl: '',
        toolTipQuestion: {
          code: 'fmc_usage',
          text: 'Cât de des consumi acest tip de produs?',
          options: getFrequencyOptions({
            0: {
              code: 'fmc_usage_adc',
              text: 'Câte țigarete fumezi într-o zi?',
              options: getQuantityOptions(99),
            },
            1: {
              code: 'fmc_usage_awc',
              text: 'Câte țigarete fumezi într-o săptămână?',
              options: getQuantityOptions(99),
            },
          }),
        },
        indexesThatTriggerMoreDetails: [0, 1],
        moreDetails: {
          accordionItems: [
            {
              title: 'Care este brandul pe care îl fumezi cel mai des?',
              contentType: 'dropdowns-2nd-as-subset-of-1st',
              content: [
                {
                  code: 'fmc_usage_brand',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'branduri selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Brand',
                      placeholder: 'Alege brand',
                    },
                    modalTitle: 'Ce brand fumezi de obicei?',
                    modalSubtitle: 'Alege brandul pe care îl fumezi cel mai des.',
                    searchable: true,
                    searchPlaceholder: 'Caută un brand',
                    withAdditionalInputForLastItem: false,
                  },
                },
                {
                  code: 'fmc_usage_sku',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'variante selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Varianta',
                      placeholder: 'Alege varianta',
                    },
                    modalTitle: 'Care dintre aceste variante?',
                    modalSubtitle: 'Alege varianta pe care o fumezi cel mai des.',
                    searchable: true,
                    searchPlaceholder: 'Caută o variantă',
                    withAdditionalInputForLastItem: false,
                  },
                },
              ],
              completed: false,
            },
            {
              title: 'Fumezi și alte branduri de țigarete?',
              contentType: 'dropdowns-2nd-as-subset-of-1st-conditioned',
              content: [
                {
                  data: {
                    items: [
                      { label: 'Da, fumez', value: true },
                      { label: 'Nu fumez', value: false },
                    ],
                    selectedItem: undefined,
                  },
                },
                {
                  code: 'fmc_usage_other_brands',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'branduri selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Brand',
                      placeholder: 'Alege brand',
                    },
                    modalTitle: 'Ce alt brand fumezi?',
                    modalSubtitle: 'Alege alt brand pe care îl fumezi.',
                    searchable: true,
                    searchPlaceholder: 'Caută un brand',
                    withAdditionalInputForLastItem: false,
                  },
                },
                {
                  code: 'fmc_usage_other_sku',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'variante selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Varianta',
                      placeholder: 'Alege varianta',
                    },
                    modalTitle: 'Care dintre aceste variante?',
                    modalSubtitle: 'Alege varianta pe care o fumezi.',
                    searchable: true,
                    searchPlaceholder: 'Caută o variantă',
                    withAdditionalInputForLastItem: false,
                  },
                },
              ],
              completed: false,
            },
          ],
          reached: false,
        },
        selected: false,
      },
      {
        termCode: 'dispozitive_electronice_care_incalzesc_dar_nu_ard_consumabile_cu_sau_fara_tutun',
        text: 'Dispozitive electronice care încălzesc, dar nu ard consumabile cu sau fără tutun',
        selectionText: '',
        imageUrl: '',
        toolTipQuestion: {
          code: 'hp_usage',
          text: 'Cât de des consumi acest tip de produs?',
          options: getFrequencyOptions({
            0: {
              code: 'hp_adc',
              text: 'Câte consumabile utilizezi într-o zi?',
              options: getQuantityOptions(60),
            },
            1: {
              code: 'hp_awc',
              text: 'Câte consumabile utilizezi într-o săptămână?',
              options: getQuantityOptions(60),
            },
          }),
        },
        indexesThatTriggerMoreDetails: [0, 1],
        moreDetails: {
          accordionItems: [
            {
              title: 'Ce dispozitive deții?',
              contentType: 'dropdowns-2nd-as-selection-of-1st-update-next-accordion-item',
              content: [
                {
                  code: 'hp_devices',
                  data: {
                    items: [],
                    enforceItemsMaxSelection: false,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'dispozitive selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Dispozitiv',
                      placeholder: 'Alege dispozitiv',
                    },
                    modalTitle: 'Ce dispozitive deții?',
                    modalSubtitle: 'Alege dispozitivele pe care le deții.',
                    searchable: true,
                    searchPlaceholder: 'Caută un dispozitiv',
                    withAdditionalInputForLastItem: true,
                    additionalLastItemInput: '',
                  },
                },
                {
                  code: 'hp_device_usage',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'branduri selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Pe care îl utilizezi cel mai des?',
                      placeholder: 'Alege',
                    },
                    modalTitle: 'Care dintre aceste variante?',
                    modalSubtitle: 'Alege varianta pe care o utilizezi cel mai des.',
                    searchable: false,
                    withAdditionalInputForLastItem: false,
                  },
                },
              ],
              completed: false,
            },
            {
              title: 'Ce consumabile utilizezi?',
              contentType: 'dropdowns-2nd-as-selection-of-1st',
              content: [
                {
                  code: 'hp_consumables_usage',
                  data: {
                    items: [],
                    enforceItemsMaxSelection: false,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'variante selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Variante',
                      placeholder: 'Alege variantele',
                    },
                    modalTitle: 'Care dintre aceste variante?',
                    modalSubtitle: 'Alege variantele pe care le consumi.',
                    searchable: true,
                    searchPlaceholder: 'Caută o variantă',
                    withAdditionalInputForLastItem: true,
                    additionalLastItemInput: '',
                  },
                },
                {
                  code: 'hp_consumables_mob',
                  data: {
                    items: [],
                    itemsMaxSelection: 1,
                    enforceItemsMaxSelection: true,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'variante selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Pe care le consumi cel mai des?',
                      placeholder: 'Alege',
                    },
                    modalTitle: 'Care dintre aceste variante?',
                    modalSubtitle: 'Alege varianta pe care o utilizezi cel mai des.',
                    searchable: false,
                    withAdditionalInputForLastItem: false,
                  },
                },
              ],
              completed: false,
            },
          ],
          reached: false,
        },
        selected: false,
      },
      {
        termCode: 'pliculete_cu_nicotina',
        text: 'Pliculețe cu nicotină',
        selectionText: '',
        imageUrl: '',
        toolTipQuestion: {
          code: 'mw_usage',
          text: 'Cât de des consumi acest tip de produs?',
          options: getFrequencyOptions({
            0: {
              code: 'mw_adc',
              text: 'Câte pliculețe utilizezi într-o zi?',
              options: getQuantityOptions(20),
            },
            1: {
              code: 'mw_awc',
              text: 'Câte pliculețe utilizezi într-o săptămână?',
              options: getQuantityOptions(60),
            },
            2: {
              code: 'mw_amc',
              text: 'Câte pliculețe utilizezi într-o lună?',
              options: getQuantityOptions(60),
            },
          }),
        },
        indexesThatTriggerMoreDetails: [0, 1, 2],
        moreDetails: {
          accordionItems: [
            {
              title: 'Ce branduri utilizezi de obicei?',
              contentType: 'dropdowns',
              content: [
                {
                  code: 'mw_usage_brands',
                  data: {
                    items: [],
                    enforceItemsMaxSelection: false,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'branduri selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Brand',
                      placeholder: 'Alege brand',
                    },
                    modalTitle: 'Ce branduri utilizezi de obicei?',
                    modalSubtitle: 'Alege brandurile pe care le utilizezi cel mai des.',
                    searchable: true,
                    searchPlaceholder: 'Caută un brand',
                    withAdditionalInputForLastItem: true,
                    additionalLastItemInput: '',
                  },
                },
              ],
              completed: false,
            },
            {
              title: 'Ce concentrație de nicotină au cele pe care le utilizezi?',
              contentType: 'dropdowns',
              content: [
                {
                  code: 'mw_level_of_nicotine',
                  data: {
                    items: [
                      { label: '1mg - 2mg' },
                      { label: '3mg - 4mg' },
                      { label: '5mg - 6mg' },
                      { label: '7mg - 8mg' },
                      { label: '9mg - 10mg' },
                      { label: '11mg - 15mg' },
                      { label: '16mg - 20mg' },
                      { label: '21mg - 30mg' },
                      { label: '31mg - 60mg' },
                      { label: 'Nu știu' },
                    ],
                    itemsMaxSelection: 10,
                    enforceItemsMaxSelection: false,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'concentrații selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Concentrație',
                      placeholder: 'Alege concentrația',
                    },
                    modalTitle: 'Ce concentrație de nicotină au cele pe care le utilizezi?',
                    modalSubtitle: 'Alege concentrațiile pe care le utilizezi.',
                    searchable: false,
                    withAdditionalInputForLastItem: false,
                  },
                },
              ],
              completed: false,
            },
          ],
          reached: false,
        },
        selected: false,
      },
      {
        termCode: 'tigareta_electronica_de_unica_folosinta',
        text: 'Țigaretă electronică de unică folosință',
        selectionText: '',
        imageUrl: '',
        toolTipQuestion: {
          code: 'modi_usage',
          text: 'Cât de des consumi acest tip de produs?',
          options: getFrequencyOptions({
            0: {
              code: 'modi_awc',
              text: 'Câte țigarete electronice de unică folosință utilizezi pe săptămână?',
              options: getQuantityOptions(60),
            },
            1: {
              code: 'modi_awc',
              text: 'Câte țigarete electronice de unică folosință utilizezi pe săptămână?',
              options: getQuantityOptions(60),
            },
            2: {
              code: 'modi_amc',
              text: 'Câte țigarete electronice de unică folosință utilizezi pe lună?',
              options: getQuantityOptions(60),
            },
          }),
        },
        indexesThatTriggerMoreDetails: [0, 1, 2],
        moreDetails: {
          accordionItems: [
            {
              title: 'Ce branduri utilizezi de obicei?',
              contentType: 'dropdowns',
              content: [
                {
                  code: 'modi_usage_brands',
                  data: {
                    items: [],
                    enforceItemsMaxSelection: false,
                    itemIdentifierFieldName: 'label',
                    selectedItems: undefined,
                    buttonTexts: {
                      selectionStateSuffix: 'branduri selectate',
                      allSelected: 'Continuă',
                    },
                    buttonLikeDropdown: {
                      label: 'Brand',
                      placeholder: 'Alege brand',
                    },
                    modalTitle: 'Ce branduri utilizezi de obicei?',
                    modalSubtitle: 'Alege brandurile pe care le utilizezi.',
                    searchable: true,
                    searchPlaceholder: 'Caută un brand',
                    withAdditionalInputForLastItem: true,
                    additionalLastItemInput: '',
                  },
                },
              ],
              completed: false,
            },
            {
              title: 'Ce tip utilizezi de obicei?',
              contentType: 'radio',
              content: [
                {
                  code: 'modi_nicotine_usage',
                  data: {
                    items: [
                      { label: 'Cu nicotină', value: 'Cu nicotină' },
                      { label: 'Fără nicotină', value: 'Fără nicotină' },
                      { label: 'Ambele', value: 'Ambele' },
                    ],
                    selectedItem: undefined,
                  },
                },
              ],
              completed: false,
            },
          ],
          reached: false,
        },
        selected: false,
      },
      {
        termCode: 'tigareta_electronica_reincarcabila',
        text: 'Țigaretă electronică reîncărcabilă',
        mention: '(cu rezervor ce poate fi reîncarcat sau cu cartușe de unică folosință)',
        selectionText: '',
        imageUrl: '',
        toolTipQuestion: {
          code: 'ecig_usage',
          text: 'Cât de des consumi acest tip de produs?',
          options: getFrequencyOptions({
            0: {
              code: 'ecig_usage_daily',
              text: 'În medie, de câte ori pe zi le utilizezi?',
              mention:
                'Prin utilizare ne referim la sesiuni în care inhalezi cel puțin câteva pufuri.',
              options: getQuantityOptions(60),
            },
            1: {
              code: 'ecig_usage_weekly',
              text: 'În medie, de câte ori pe săptămână le utilizezi?',
              mention:
                'Prin utilizare ne referim la sesiuni în care inhalezi cel puțin câteva pufuri.',
              options: getQuantityOptions(200),
            },
            2: {
              code: 'ecig_usage_monthly',
              text: 'În medie, de câte ori pe lună le utilizezi?',
              mention:
                'Prin utilizare ne referim la sesiuni în care inhalezi cel puțin câteva pufuri.',
              options: getQuantityOptions(200),
            },
          }),
        },
        indexesThatTriggerMoreDetails: [0, 1, 2],
        moreDetails: {
          accordionItems: [
            {
              title: 'Ce branduri utilizezi de obicei? Notează, te rugăm, mai jos.',
              contentType: 'text',
              content: [
                {
                  code: 'ecig_usage_brands',
                  data: {
                    value: '',
                  },
                },
              ],
              completed: false,
            },
          ],
          reached: false,
        },
        selected: false,
      },
    ],
  },
});

const slice = createSlice({
  name: 'tasteProfile',
  initialState: getInitialState(),
  reducers: {
    resetState: (state) => {
      state.mainQuestion = getInitialState().mainQuestion;
    },
    includeTaxonomyItems: (
      state,
      action: {
        payload: { taxonomyItems: CustomQuizzTaxonomyItem[] };
      },
    ) => {
      const { taxonomyItems } = action.payload;

      const options = state.mainQuestion.options;

      options.forEach((option) => {
        const targetedTaxonomyItem = taxonomyItems.find(
          ({ field_term_code }) => field_term_code === option.termCode,
        );

        if (!targetedTaxonomyItem) {
          return;
        }

        option.text = targetedTaxonomyItem.name;
        option.imageUrl = targetedTaxonomyItem.field_image;
      });

      const fmcTaxonomyItems = taxonomyItems
        .filter(({ parent_name }) => parent_name === 'FMC')
        .map(({ name }) => {
          const subItems = taxonomyItems
            .filter(({ parent_name }) => parent_name === name)
            .map(({ name }) => ({ label: name }));
          return { label: name, subItems };
        });
      options[0].moreDetails.accordionItems[0].content[0].data.items = fmcTaxonomyItems;
      options[0].moreDetails.accordionItems[1].content[1].data.items = fmcTaxonomyItems;

      const hpDevicesTaxonomyItems = taxonomyItems
        .filter(({ parent_name }) => parent_name === 'HP')
        .map(({ name }) => {
          const subItems = taxonomyItems
            .filter(({ parent_name }) => parent_name === name)
            .map(({ name }) => ({ label: name }));
          return { label: name, subItems };
        });
      options[1].moreDetails.accordionItems[0].content[0].data.items = [
        ...hpDevicesTaxonomyItems,
        {
          label: 'Alt dispozitiv, te rugăm să menționezi care',
        },
      ];
      options[1].moreDetails.accordionItems[0].content[0].data.itemsMaxSelection =
        hpDevicesTaxonomyItems.length + 1;

      const mwTaxonomyItems = taxonomyItems
        .filter(({ parent_name }) => parent_name === 'MW')
        .map(({ name }) => ({ label: name }));
      options[2].moreDetails.accordionItems[0].content[0].data.items = [
        ...mwTaxonomyItems,
        {
          label: 'Alt brand, te rugăm să precizezi care',
        },
      ];
      options[2].moreDetails.accordionItems[0].content[0].data.itemsMaxSelection =
        mwTaxonomyItems.length + 1;

      const modiTaxonomyItems = taxonomyItems
        .filter(({ parent_name }) => parent_name === 'Modi')
        .map(({ name }) => ({ label: name }));
      options[3].moreDetails.accordionItems[0].content[0].data.items = [
        ...modiTaxonomyItems,
        {
          label: 'Alt brand, te rugăm să precizezi care',
        },
      ];
      options[3].moreDetails.accordionItems[0].content[0].data.itemsMaxSelection =
        modiTaxonomyItems.length + 1;
    },
    setAvailableDateAndPoints: (
      state,
      action: {
        payload: { availableDateDMY: string; points: string; period: string };
      },
    ) => {
      const { availableDateDMY, points, period } = action.payload;

      state.availableDateDMY = availableDateDMY;
      state.points = points;

      const units: Record<string, string> = {
        Day: 'zi',
        Days: 'zile',
        Month: 'lună',
        Months: 'luni',
        Year: 'an',
        Years: 'ani',
      };
      const [value, unit] = period.split(' ');
      state.period = `${value} ${units[unit]}`;
    },
    markOptionAsPreselected: (
      state,
      action: {
        payload: { mainOption: TasteProfileMainOption; option: TasteProfileOption };
      },
    ) => {
      const { mainOption, option } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      targetedMainOption.toolTipQuestion = {
        ...targetedMainOption.toolTipQuestion,
        options: targetedMainOption.toolTipQuestion.options.map((o) => ({
          ...o,
          preselected: o.text === option.text,
        })),
      };
    },
    markMainOptionAsSelected: (
      state,
      action: {
        payload: { mainOption: TasteProfileMainOption; options: TasteProfileOption[] };
      },
    ) => {
      const { mainOption, options } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      targetedMainOption.selected = true;
      targetedMainOption.selectionText = options.map(({ text }) => text).join(' · ');
      targetedMainOption.toolTipQuestion = {
        ...targetedMainOption.toolTipQuestion,
        options: targetedMainOption.toolTipQuestion.options.map((option) => {
          if (option.secondaryQuestion && options[1]) {
            option.secondaryQuestion.options = option.secondaryQuestion.options.map(
              (secondaryOption) => ({
                ...secondaryOption,
                selected: secondaryOption.text === options[1].text,
              }),
            );
          }
          return {
            ...option,
            selected: option.text === options[0].text,
          };
        }),
      };
    },
    markMainOptionAsDeselected: (
      state,
      action: {
        payload: { mainOption: TasteProfileMainOption };
      },
    ) => {
      const { mainOption } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      targetedMainOption.selected = false;
      targetedMainOption.selectionText = '';
      targetedMainOption.toolTipQuestion = {
        ...targetedMainOption.toolTipQuestion,
        options: targetedMainOption.toolTipQuestion.options.map((option) => ({
          ...option,
          preselected: false,
          selected: false,
        })),
      };
    },
    markMainOptionMoreDetailsAsReached: (
      state,
      action: {
        payload: { mainOption: TasteProfileMainOption };
      },
    ) => {
      const { mainOption } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      targetedMainOption.moreDetails.reached = true;
    },
    addSelectedItemsDropdown: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          contentIndex: number;
          selectedItems: any;
          additionalLastItemInput?: string;
        };
      },
    ) => {
      const {
        mainOption,
        accordionItemIndex,
        contentIndex,
        selectedItems,
        additionalLastItemInput,
      } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];
      const targetedAccordionItemContentData = targetedAccordionItem.content[contentIndex].data;

      targetedAccordionItem.completed =
        selectedItems.length > 0 &&
        targetedAccordionItem.content
          .filter((_, index: number) => index !== contentIndex)
          .every(({ data }: any) => data.selectedItems !== undefined);
      targetedAccordionItemContentData.selectedItems = selectedItems;

      if (additionalLastItemInput) {
        targetedAccordionItemContentData.additionalLastItemInput = additionalLastItemInput;
      }
    },
    addSelectedItemsFor1stDropdown: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          selectedItems: any;
          indexOffset: number;
          relation: 'subset' | 'selection';
          additionalLastItemInput?: string;
          nextAccordionItemIndex?: number;
        };
      },
    ) => {
      const {
        mainOption,
        accordionItemIndex,
        selectedItems,
        indexOffset,
        relation,
        additionalLastItemInput,
        nextAccordionItemIndex,
      } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];
      const firstDropDown = targetedAccordionItem.content[0 + indexOffset];
      const secondDropdown = targetedAccordionItem.content[1 + indexOffset];

      targetedAccordionItem.completed = false;
      firstDropDown.data.selectedItems = selectedItems;

      switch (relation) {
        case 'subset':
          secondDropdown.data.items = selectedItems.flatMap(({ subItems }: any) => subItems);
          break;

        case 'selection':
          secondDropdown.data.items = selectedItems;

          if (additionalLastItemInput) {
            firstDropDown.data.additionalLastItemInput = additionalLastItemInput;
            secondDropdown.data.items = [
              ...secondDropdown.data.items.slice(0, -1),
              {
                label: additionalLastItemInput,
              },
            ];
          }
          break;
      }

      secondDropdown.data.selectedItems = undefined;

      if (nextAccordionItemIndex) {
        const nextAccordionItem =
          targetedMainOption.moreDetails.accordionItems[nextAccordionItemIndex];
        const nextFirstDropDown = nextAccordionItem.content[0 + indexOffset];
        const nextSecondDropdown = nextAccordionItem.content[1 + indexOffset];

        nextAccordionItem.completed = false;

        const nextFirstDropDownItems = selectedItems
          .flatMap(({ subItems }: any) => subItems || [])
          .filter(
            (subItem: any, index: number, self: any) =>
              index === self.findIndex(({ label }: any) => label === subItem.label),
          );
        nextFirstDropDown.data.items = nextFirstDropDownItems;
        nextFirstDropDown.data.itemsMaxSelection = nextFirstDropDownItems.length;
        if (nextFirstDropDown.data.withAdditionalInputForLastItem) {
          nextFirstDropDown.data.items.push({
            label: 'Altă variantă, te rugăm să menționezi care',
          });
          nextFirstDropDown.data.itemsMaxSelection += 1;
          nextFirstDropDown.data.additionalLastItemInput = '';
        }
        nextFirstDropDown.data.selectedItems = undefined;
        nextSecondDropdown.data.items = [];
        nextSecondDropdown.data.selectedItems = undefined;
      }
    },
    addSelectedItemsFor2ndDropdown: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          selectedItems: any;
          indexOffset: number;
        };
      },
    ) => {
      const { mainOption, accordionItemIndex, selectedItems, indexOffset } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];

      targetedAccordionItem.completed = true;
      targetedAccordionItem.content[1 + indexOffset].data.selectedItems = selectedItems;
    },
    addSelectedItemForRadioInDropdowns2ndAsSubsetOf1stConditioned: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          selectedItem: any;
        };
      },
    ) => {
      const { mainOption, accordionItemIndex, selectedItem } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];
      const accordionItemContent = targetedAccordionItem.content;

      accordionItemContent[0].data.selectedItem = selectedItem;
      accordionItemContent.forEach((contentItem: any, index: number) => {
        if (index === 0) {
          return;
        }
        if (index === 2) {
          contentItem.data.items = [];
        }
        contentItem.data.selectedItems = undefined;
      });

      targetedAccordionItem.completed = !selectedItem.value;
    },
    addSelectedItemForRadio: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          selectedItem: any;
        };
      },
    ) => {
      const { mainOption, accordionItemIndex, selectedItem } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];

      targetedAccordionItem.completed = true;
      targetedAccordionItem.content[0].data.selectedItem = selectedItem;
    },
    addValueForText: (
      state,
      action: {
        payload: {
          mainOption: TasteProfileMainOption;
          accordionItemIndex: number;
          value: string;
        };
      },
    ) => {
      const { mainOption, accordionItemIndex, value } = action.payload;

      const targetedMainOption = state.mainQuestion.options.find(
        ({ text }) => text === mainOption.text,
      );

      if (!targetedMainOption) {
        return;
      }

      const targetedAccordionItem =
        targetedMainOption.moreDetails.accordionItems[accordionItemIndex];

      targetedAccordionItem.completed = value !== '';
      targetedAccordionItem.content[0].data.value = value;
    },
  },
});

export const {
  resetState,
  includeTaxonomyItems,
  setAvailableDateAndPoints,
  markOptionAsPreselected,
  markMainOptionAsSelected,
  markMainOptionAsDeselected,
  markMainOptionMoreDetailsAsReached,
  addSelectedItemsDropdown,
  addSelectedItemsFor1stDropdown,
  addSelectedItemsFor2ndDropdown,
  addSelectedItemForRadioInDropdowns2ndAsSubsetOf1stConditioned,
  addSelectedItemForRadio,
  addValueForText,
} = slice.actions;

export default slice.reducer;
