import api from '../config/api';
import Endpoints from '../config/api/endpoints';

export const reCaptchaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReCaptchaValidationStatus: builder.mutation<GetReCaptchaValidationStatusResponse, {}>({
      query: () => ({
        url: Endpoints.getReCaptchaValidationStatus,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetReCaptchaValidationStatusMutation } = reCaptchaApi;
