import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';
import { ScreenNames } from '../ScreenNames';

const Campaigns = protectedLazyScreen(() => import('./Campaigns'));

const Stack = createStackNavigator();

const CampaignsListStack = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={ScreenNames.campaignsList}
      screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.campaignsList}
        key={ScreenNames.campaignsList}
        options={{ title: t('CAMPAIGNS_TITLE'), headerShown: false }}
        component={Campaigns}
      />
    </Stack.Navigator>
  );
};

export { CampaignsListStack };
